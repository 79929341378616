import AxiosInstance from "../axios/axios.instance";
import { IQueryParams } from "medgo-query-generator";
import { PossibleFetchs, PossibleApis } from "../constant/Api.constants";
import generateParams from "../toolbox/customParamGenerator";

export function postFetchFactory<T>(
    modalName: PossibleFetchs,
    api: string = PossibleApis.serviceApi,
    apiVersion: number = 1
): (defaultParams: IQueryParams, queryParams?: IQueryParams) => Promise<T[]> {
    return async (defaultParams: IQueryParams, queryParams?: IQueryParams): Promise<T[]> => {
        const params = Object.assign({}, defaultParams, queryParams ? queryParams : {});
        const res = await AxiosInstance.post(api + `/api/v${apiVersion}/`.concat(modalName), {
            paramString: generateParams(params)
        });
        const data = res.data && res.data.data ? res.data.data : res.data === "" ? [] : res.data;
        return data;
    };
}

export function postFetchNormalizedFactory<T>(
    modalName: PossibleFetchs,
    api: string = PossibleApis.serviceApi
): (queryParams: IQueryParams, defaultParams?: IQueryParams) => Promise<T[]> {
    return async (defaultParams: IQueryParams, queryParams?: IQueryParams): Promise<T[]> => {
        const params = Object.assign({}, defaultParams, queryParams ? queryParams : {});
        const res = await AxiosInstance.post(api + "/api/v1/".concat(modalName), {
            paramString: generateParams(params)
        });
        return res.data.reduce((p: any, c: any): any => {
            p[c.id ? c.id : -1] = c;
            return p;
        }, {});
    };
}
