import { IFilterLang } from "../interface/Filter.interface";

export const enFilter: IFilterLang = {
    institution: "Establishment / Site",
    job: "job",
    period: "period",
    service: "department",
    pole: "pole",
    lastFourWeeks: "Last 4 weeks",
    lastSixMonths: "Last 6 months",
    lastTwelveMonths: "Last 12 months",
    lastYear: "Last year",
    onGoingYear: "Current year"
};
