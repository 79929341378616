import { IReduxAction } from "../store.types";
import InstitutionTypes from "../type/institution.types";
import { fetchInstitutionById, fetchInstitutions } from "../../service/institution/institution.service";

export function setIsLoadingFetchInstitutionsAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionTypes.SET_IS_LOADING_FETCH_INSTITUTIONS,
            payload: bool
        });
    };
}

export function fetchInstitutionsAction(): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const institutions = await fetchInstitutions({});
        dispatch({
            type: InstitutionTypes.FETCH_INSTITUTIONS,
            payload: institutions
        });
    };
}

export function setIsLoadingFetchInstitutionByIdAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionTypes.SET_IS_LOADING_FETCH_INSTITUTION_BY_ID,
            payload: bool
        });
    };
}

export function fetchInstitutionByIdAction(id: number): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const institution = await fetchInstitutionById(id);
        dispatch({
            type: InstitutionTypes.FETCH_INSTITUTION,
            payload: institution
        });
    };
}
