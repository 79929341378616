import { INavbarLang } from "../interface/Navbar.interface";

export const frNavbar: INavbarLang = {
    proMissions: "Mes missions",
    proVacataires: "Mon réseau",
    proContracts: "Mes contrats",
    dashboard: "Tableau de bord",
    profile: "mon compte",
    myInstitutions: "mes établissements",
    myNotifications: "mes notifications",
    myConfig: "paramétrage",
    logout: "déconnexion",
    contractDemo: "⚡ Mes contrats",
    talent: "Talent"
};
