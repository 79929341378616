import { IMission } from "../../../Models/Mission";
import { fetchFactory } from "../../module/factory/fetch.factory";
import { IQueryParams } from "medgo-query-generator";
import { PossibleFetchs, PossibleApis, FetchLimits } from "../../module/constant/Api.constants";

export async function fetchMissionsCount(params: IQueryParams): Promise<any[]> {
    return fetchFactory<IMission>(PossibleFetchs.missions, PossibleApis.serviceApi)(
        {
            select: ["$count"]
        },
        params
    );
}

export async function fetchMissions(params: IQueryParams): Promise<any[]> {
    return fetchFactory<IMission>(PossibleFetchs.missions, PossibleApis.serviceApi)(
        {
            select: [
                "id",
                "institution",
                "job",
                "service",
                "beginAt",
                "createdAt",
                "isPlanning",
                "isPastMission",
                "isFutureMission",
                "isCanceled",
                "isFilled",
                "canceledBecause",
                "worker",
                "nbWorkedMinutes",
                "interimAgencyCandidate",
                "workerStatusAtMissionProvided",
                "motif"
            ],
            limit: params.limit ? params.limit : FetchLimits.missions
        },
        params
    );
}
