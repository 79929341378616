import { IReduxAction } from "../store.types";
import JobTypes from "../type/job.types";
import { fetchJobById, fetchJobs } from "../../service/job/job.service";

export function setIsLoadingFetchJobsAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: JobTypes.SET_IS_LOADING_FETCH_JOBS,
            payload: bool
        });
    };
}

export function fetchJobsAction(idInstitutions: number[]): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const jobs = await fetchJobs({
            query: {
                institution: idInstitutions
            },
            orderby: [
                {
                    columnName: "name",
                    orientation: "ASC"
                }
            ]
        });
        dispatch({
            type: JobTypes.FETCH_JOBS,
            payload: jobs
        });
    };
}

export function setIsLoadingFetchJobByIdAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: JobTypes.SET_IS_LOADING_FETCH_JOB_BY_ID,
            payload: bool
        });
    };
}

export function fetchJobByIdAction(id: number): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const job = await fetchJobById(id);
        dispatch({
            type: JobTypes.FETCH_JOB,
            payload: job
        });
    };
}
