import { IMission } from "../../../Models/Mission";
import { fetchFactory } from "../../module/factory/fetch.factory";
import { IQueryParams } from "medgo-query-generator";
import { PossibleFetchs, PossibleApis, FetchLimits } from "../../module/constant/Api.constants";

export async function fetchMissionMotifCreations(params: IQueryParams): Promise<any[]> {
    return fetchFactory<IMission>(PossibleFetchs.missionMotifCreations, PossibleApis.serviceApi)(
        {
            select: ["id", "missionmotifcreationcategory"],
            limit: FetchLimits.missionMotifCreations
        },
        params
    );
}
