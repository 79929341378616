import { IHRMonitoringLang } from "../interface/HRMonitoring.interface";

export const deHRMonitoring: IHRMonitoringLang = {
    nbOfWorkingHoursFulfilled: "Anzahl der geleisteten Stunden",
    workerOrAgent: "Festangestellte",
    temporaryWorkerOrContractor: "Aushilfskraft",
    interimOrAgencyContractor: "Aushilfskräfte",
    pool: "Pool",
    total: "Total",
    weekBeginningOnXXX: "Woche vom __VAR",
    missionMotifDistribution: "Grund der Ausfälle",
    absenteeism: "Ausfallquote",
    needInReiforcement: "Bedarf an Ersatzpersonal",
    vacantPosition: "Personalmangel",
    vacation: "Urlaub",
    other: "Andere",
    institutionAttractiveness: "Einspringquote der Einrichtung",
    institutionAttractivenessTooltip:
        "<strong>Einspringquote der Einrichtung</strong> zeigt das Verhältnis zwischen den übernommenen Schichten und allen veröffentlichten Schichten innerhalb der Einrichtung",
    poleAttractiveness: "Einspringquote des Bereichs.",
    poleAttractivenessTooltip:
        "<strong>Einspringquote des Bereichs</strong> zeigt das Verhältnis zwischen den übernommenen Schichten und allen veröffentlichten Schichten innerhalb des Bereichs auf.",
    serviceAttractiveness: "Einspringquote der Stationen",
    serviceAttractivenessTooltip:
        "<strong> Einspringquote der Station</strong> zeigt das Verhältnis zwischen den übernommenen Schichten und allen veröffentlichten Schichten innerhalb der Station. auf.",
    hourFirstLetter: "Std.",
    notEnoughMissionsForThisInstitution: "Nicht genügend Schichten für diese Einrichtung (mindestens 5 Schichten)",
    notEnoughMissionsForThisPole: "Nicht genügend Schichten für diesen Bereich (mindestens 5 Schichten)",
    notEnoughMissionsForThisService: "Nicht genügend Schichten für diese Station (mindestens 5 Schichten)"
};
