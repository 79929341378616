import { IReduxAction } from "../store.types";
import NetworkTypes from "../type/network.types";

const initialState: any = {
    salaryWorkerCounts: [],
    isLoadingSalaryWorkerCounts: false,
    temporaryWorkerCounts: [],
    isLoadingTemporaryWorkerCounts: false,
    activeNetworkEvolution: [],
    isLoadingActiveNetworkEvolution: false,
    evolutionMode: 0
};

export default function networkReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case NetworkTypes.FETCH_SALARY_WORKERS_COUNTS:
            return {
                ...state,
                salaryWorkerCounts: action.payload
            };
        case NetworkTypes.SET_IS_LOADING_FETCH_SALARY_WORKERS_COUNT:
            return {
                ...state,
                isLoadingSalaryWorkerCounts: action.payload
            };
        case NetworkTypes.FETCH_TEMPORARY_WORKERS_COUNTS:
            return {
                ...state,
                temporaryWorkerCounts: action.payload
            };
        case NetworkTypes.SET_IS_LOADING_FETCH_TEMPORARY_WORKERS_COUNT:
            return {
                ...state,
                isLoadingTemporaryWorkerCounts: action.payload
            };
        case NetworkTypes.GET_ACTIVE_NETWORK_EVOLUTION:
            return {
                ...state,
                activeNetworkEvolution: action.payload
            };
        case NetworkTypes.SET_IS_LOADING_ACTIVE_NETWORK_EVOLUTION:
            return {
                ...state,
                isLoadingActiveNetworkEvolution: action.payload
            };
        case NetworkTypes.SET_NETWORK_EVOLUTION_MODE:
            return {
                ...state,
                evolutionMode: action.payload
            };
        default:
            return state;
    }
}
