import { IReduxAction } from "../store.types";
import JobTypes from "../type/job.types";

const initialState: any = {
    jobs: [],
    job: {}
};
export default function jobsReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case JobTypes.FETCH_JOBS:
            return {
                ...state,
                jobs: action.payload
            };
        case JobTypes.FETCH_JOB:
            return {
                ...state,
                job: action.payload
            };
        case JobTypes.SET_IS_LOADING_FETCH_JOBS:
            return {
                ...state,
                isLoadingFetchJobs: action.payload
            };
        case JobTypes.SET_IS_LOADING_FETCH_JOB_BY_ID:
            return {
                ...state,
                isLoadingFetchJob: action.payload
            };
        default:
            return state;
    }
}
