import { IReduxAction } from "../store.types";
import SpecialtiesTypes from "../type/specialty.types";

const initialState: any = {
    specialties: [],
    specialty: {},
    isLoadingFetchSpecialties: false,
    isLoadingFetchSpecialty: false
};

export default function specialtiesReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case SpecialtiesTypes.FETCH_SPECIALTIES:
            return {
                ...state,
                specialties: action.payload
            };
        case SpecialtiesTypes.FETCH_SPECIALTY:
            return {
                ...state,
                specialty: action.payload
            };
        case SpecialtiesTypes.SET_IS_LOADING_FETCH_SPECIALTIES:
            return {
                ...state,
                isLoadingFetchSpecialties: action.payload
            };
        case SpecialtiesTypes.SET_IS_LOADING_FETCH_SPECIALTY_BY_ID:
            return {
                ...state,
                isLoadingFetchSpecialty: action.payload
            };
        default:
            return state;
    }
}
