import { IReduxAction } from "../store.types";
import InstitutionHasWorkerTypes from "../type/institutionHasWorker.types";

const initialState: any = {
    institutionHasWorkers: [],
    isLoadingFetchInstitutionHasWorkers: false
};

export default function institutionHasWorkerReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InstitutionHasWorkerTypes.FETCH_INSTITUTION_HAS_WORKERS:
            return {
                ...state,
                institutionHasWorkers: action.payload
            };

        case InstitutionHasWorkerTypes.SET_IS_LOADING_FETCH_INSTITUTION_HAS_WORKERS:
            return {
                ...state,
                isLoadingFetchInstitutionHasWorkers: action.payload
            };

        default:
            return state;
    }
}
