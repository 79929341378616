import { IPeriodInfo } from "../../../Models/PeriodInfo";
import store from "../../store";
import { ISelectLabelValue } from "@hublo/legacy-components";
import { ILang } from "../../lang/interface";

const DEFAULT_PERIOD_INFO: IPeriodInfo = {
    period: 6,
    unit: "month",
    mode: "rolling"
};

export function getDefaultPeriodFilterValue(): ISelectLabelValue {
    // @ts-ignore
    const lang = store.getState().language.lang;
    return {
        label: lang.lastSixMonths,
        value: 0
    };
}

export const getPeriodFilters = (lang: ILang): ISelectLabelValue[] => {
    return [
        {
            label: lang.lastSixMonths,
            value: 0
        },
        {
            label: lang.lastFourWeeks,
            value: 1
        },
        {
            label: lang.lastTwelveMonths,
            value: 2
        },
        {
            label: lang.lastYear,
            value: 3
        },
        {
            label: lang.onGoingYear,
            value: 4
        }
    ];
};

export const getPeriodInfoFromPeriodFilterValue = (period?: ISelectLabelValue): IPeriodInfo => {
    if (period && period.value) {
        switch (period.value) {
            case 0:
                return {
                    period: 6,
                    unit: "month",
                    mode: "rolling"
                };
            case 1:
                return {
                    period: 4,
                    unit: "week",
                    mode: "rolling"
                };
            case 2:
                return {
                    period: 12,
                    unit: "month",
                    mode: "rolling"
                };
            case 3:
                return {
                    period: 1,
                    unit: "year",
                    mode: "strict"
                };
            case 4:
                return {
                    period: 0,
                    unit: "year",
                    mode: "strict"
                };
        }
    }
    return DEFAULT_PERIOD_INFO;
};
