import { enCommon } from "./common";
import { enWorker } from "./worker";
import { enNavbar } from "./navbar";
import { enDashboard } from "./dashboard";
import { enFilter } from "./filter";
import { enNetwork } from "./network";
import { enHRMonitoring } from "./hrMonitoring";
import { enMission } from "./mission";
import { enFooter } from "./footer";
export default Object.assign(
    {},
    enCommon,
    enWorker,
    enNavbar,
    enDashboard,
    enFilter,
    enNetwork,
    enHRMonitoring,
    enMission,
    enFooter
);
