import { IFooterLang } from "../interface/Footer.interface";

export const frFooter: IFooterLang = {
    customerPartners: "Clients/Partenaires",
    solution: "Solution de gestion des remplacements",
    about: "À propos",
    contact: "Contact",
    blog: "Blog",
    whitePapers: "Livres blancs",
    faq: "FAQ",
    pages: "pages",
    weAreConnected: "nous sommes connectés",
    allRightsReserved: "Tous droits réservés.",
    TermsOfService: "Conditions générales d'utilisation",
    languages: "langues",
    language: "Langue",
    noResult: "Aucun résultat",
    mstaffSolution: "Solution de gestion des recrutements"
};
