import { ICommonLang } from "../interface/Common.interface";

export const frCommon: ICommonLang = {
    search: "rechercher",
    resetFilters: "Réinitialiser les filtres",
    next: "suivant",
    previous: "précédant",
    notSpecifiedMale: "non renseigné",
    notSpecifiedFemale: "non renseignée",
    download: "télécharger",
    new: "nouveau",
    anErrorOccured: "Une erreur s’est produite",
    maintenanceBanner:
        "Hublo subit des ralentissements et nous faisons tout notre possible pour corriger cela. Nous sommes désolés pour la gêne occasionnée."
};
