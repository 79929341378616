import { IMissionLang } from "../interface/Mission.interface";

export const deMission: IMissionLang = {
    missionNumberEvolution: "Entwicklung der Anzahl der Schichten",
    allMissions: "Alle Schichten",
    urgent: "Dringend",
    createdMissions: "Erstellte Schichten",
    urgentMissionTooltip:
        "<strong>Eine dringende Schicht</strong> ist eine Schicht, bei der weniger als 48 Stunden zwischen der Erstellung der Schicht und Schichtbeginn liegen.",
    missionFilledRate: "Anzahl der übernommenen Schichten",
    posted: "Veröffentlicht",
    declared: "Zugewiesen",
    planned: "Geplant",
    missionTypeRepartition: "Aufteilung der Schichten nach Status",
    missionCreatedNumberByService: "Aufteilung der Schichten nach Station",
    missionCanceledRate: "Rate der stornierten Schichten",
    cancelRate: "Rate",
    filledNotFilledRepartition: "Aufteilung zugewiesen / nicht zugewiesen",
    rateOfCanceledMissions: "Rate der stornierten Aufträge",
    cancellationMotifsInstitution: {
        activityReduction: "Verringerte Aktivität",
        workerFindOutOfNetwork: "Pflegefachkraft wurde außerhalb von Hublo gefunden",
        institutionInterim: "Mitarbeiterüberlassung",
        institutionNoWorkerNeeded: "Situation wurde ohne Ersatz bewältigt",
        institutionTypingError: "Eingabefehler",
        nbHourForWorkerOverCome: "Anzahl der Überstunden wurde überstiegen",
        findOutOfHublo: "Ersatz wurde außerhalb von Hublo gefunden ",
        internalOrganisation: "Interne Umstrukturierung",
        institutionOtherReason: "Ein anderer Grund"
    },
    cancellationMotifsWorker: {
        cancelByWorker: "Stornierung vom Mitarbeiter",
        workerDidntCome: "Die Pflegefachkraft ist nicht erschienen"
    },
    cancellationMotifForInstitution: "Grund der Stornierung – Einrichtung",
    cancellationMotifForWorker: "Grund der Stornierung - Pflegefachkraft",
    cancelledFilled: "Stornierung – zugewiesen",
    cancelledNotFilled: "Stornierung – nicht zugewiesen",
    outsidePole: "Außerhalb des Bereichs",
    unspecifiedService: "STATION nicht angegeben"
};
