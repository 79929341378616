import { IReduxAction } from "../store.types";
import { ILevel } from "../../../Models/Level";
import ServiceTypes from "../type/service.types";
import FiltersTypes from "../type/filter.types";
import { fetchServiceById, fetchServices } from "../../service/service/service.service";
import { getEmptyServiceInstance } from "../../module/constant/EmptyObject.constant";
import store from "..";
import { IInstitutionOption } from "../../../Models/InstitutionOption";

export function setIsLoadingFetchServicesAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ServiceTypes.SET_IS_LOADING_FETCH_SERVICES,
            payload: bool
        });
    };
}

export function fetchServicesAction(
    idAdmin: number,
    isAllowedToSeeMissionOutsideService: boolean
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const services = await fetchServices({
            query: {
                isOnForCreatingMission: true
            },
            orderby: [
                {
                    columnName: "name",
                    orientation: "ASC"
                }
            ]
        });
        let isMyServices = {};
        if (!isAllowedToSeeMissionOutsideService) {
            const myServices = await fetchServices({
                query: {
                    admin: idAdmin
                }
            });
            isMyServices = myServices.reduce((p, c): { [key: number]: boolean } => {
                p[c.id] = true;
                return p;
            }, {} as any);
        }
        const restrictedServices = services.filter(
            // @ts-ignore
            (s): boolean => isAllowedToSeeMissionOutsideService || isMyServices[s.id]
        );
        const hasUnspecifiedService = Object.values<IInstitutionOption>(
            (store.getState() as {
                institutionsOptions: any;
            }).institutionsOptions.institutionsOptions
        ).reduce((p: IInstitutionOption, c: IInstitutionOption): IInstitutionOption => {
            (Object.keys(c) as (keyof IInstitutionOption)[]).forEach((key): void => {
                // @ts-ignore
                p[key] = p[key] || c[key];
            });
            return p;
        }, {}).hasUnspecifiedService;
        if (hasUnspecifiedService === true) {
            restrictedServices.push(getEmptyServiceInstance());
        }
        dispatch({
            type: ServiceTypes.FETCH_SERVICES,
            payload: restrictedServices
        });
        dispatch({
            type: FiltersTypes.SET_FILTERED_SERVICES,
            payload: restrictedServices
        });
    };
}

export function setIsLoadingFetchServiceByIdAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ServiceTypes.SET_IS_LOADING_FETCH_SERVICE_BY_ID,
            payload: bool
        });
    };
}

export function fetchServiceByIdAction(id: number): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const service = await fetchServiceById(id);
        dispatch({
            type: ServiceTypes.FETCH_SERVICE,
            payload: service
        });
    };
}
