import { fetchByIdFactory } from "../../module/factory/fetchById.factory";
import { ISpecialty } from "../../../Models/Specialty";
import { fetchFactory } from "../../module/factory/fetch.factory";
import { IQueryParams } from "medgo-query-generator";
import { PossibleFetchs, PossibleApis, FetchLimits } from "../../module/constant/Api.constants";

export async function fetchSpecialtyById(id: number): Promise<ISpecialty> {
    return fetchByIdFactory<ISpecialty>(PossibleFetchs.specialties, PossibleApis.serviceApi)(id, {
        select: ["id", "name", "job", "institution"]
    });
}
export async function fetchSpecialties(params: IQueryParams): Promise<ISpecialty[]> {
    return fetchFactory<ISpecialty>(PossibleFetchs.specialties, PossibleApis.serviceApi)(
        {
            select: ["id", "name", "job", "institution"],
            limit: FetchLimits.specialties
        },
        params
    );
}
