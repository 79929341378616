enum FilterTypes {
    SET_SELECTED_INSTITUTIONS = "SET_SELECTED_INSTITUTIONS",
    SET_SELECTED_JOBS = "SET_SELECTED_JOBS",
    SET_SELECTED_PERIOD = "SET_SELECTED_PERIOD",
    SET_FILTERED_SERVICES = "SET_FILTERED_SERVICES",
    SET_SELECTED_SERVICES = "SET_SELECTED_SERVICES",
    SET_FILTERED_POLES = "SET_FILTERED_POLES",
    SET_SELECTED_POLES = "SET_SELECTED_POLES"
}
export default FilterTypes;
