import { IHRMonitoringLang } from "../interface/HRMonitoring.interface";

export const esHRMonitoring: IHRMonitoringLang = {
    absenteeism: "Absentismo ",
    hourFirstLetter: "h",
    institutionAttractiveness: "Atractividad de los establecimientos y sanitarios",
    institutionAttractivenessTooltip:
        "La <strong>atractividad de un establecimiento sanitario</strong> corresponde a la proporción de coberturas cubiertas con respecto al número total de coberturas publicadas en ese establecimiento sanitario.",
    interimOrAgencyContractor: "ETT - Interino agencia ",
    missionMotifDistribution: "Reparto de los motivos de sustitución",
    nbOfWorkingHoursFulfilled: "Número de horas trabajadas según el tipo de sustituto",
    needInReiforcement: "Necesidad de refuerzo ",
    notEnoughMissionsForThisInstitution:
        "No hay suficientes coberturas en este establecimiento sanitario (5 coberturas como mínimo) ",
    notEnoughMissionsForThisPole: "No hay suficientes coberturas en esta área (5 coberturas como mínimo) ",
    notEnoughMissionsForThisService: "No hay suficientes coberturas en esta unidad (5 coberturas como mínimo) ",
    other: "Otro",
    poleAttractiveness: "Atractividad de las áreas",
    poleAttractivenessTooltip:
        "La <strong>atractividad de una área </strong> corresponde a la proporción de coberturas cubiertas con respecto al número total de coberturas publicadas en esa área.",
    pool: "Equipo",
    serviceAttractiveness: "Atractividad de las unidades",
    serviceAttractivenessTooltip:
        "La <strong>atractividad de una unidad o un servicio </strong> corresponde a la proporción de coberturas cubiertas con respecto al número total de coberturas publicadas en esa unidad o ese servicio.",
    temporaryWorkerOrContractor: "Interino interno - Contractual ",
    total: "Total",
    vacantPosition: "Vacante de trabajo",
    vacation: "Vacaciones",
    weekBeginningOnXXX: "Semana del __VAR",
    workerOrAgent: "Empleado - Agente del establecimiento"
};
