import AdminTypes from "../type/admin.types";
import { IReduxAction } from "../store.types";
import { fetchAdmin } from "../../service/admin/admin.service";

export function fetchAdminAction(idAdmin: number): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const admins = await fetchAdmin({
            query: { id: idAdmin }
        });
        return dispatch({
            type: AdminTypes.FETCH_ADMIN,
            payload: admins[0]
        });
    };
}
