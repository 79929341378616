import { fetchByIdFactory } from "../../module/factory/fetchById.factory";
import { IPole } from "../../../Models/Pole";
import { fetchFactory } from "../../module/factory/fetch.factory";
import { IQueryParams } from "medgo-query-generator";
import { PossibleFetchs, PossibleApis, FetchLimits } from "../../module/constant/Api.constants";

export async function fetchPoleById(id: number): Promise<IPole> {
    return fetchByIdFactory<IPole>(PossibleFetchs.poles, PossibleApis.serviceApi)(id, {
        select: ["id", "name", "institution"]
    });
}
export async function fetchPoles(params: IQueryParams): Promise<IPole[]> {
    return fetchFactory<IPole>(PossibleFetchs.poles, PossibleApis.serviceApi)(
        {
            select: ["id", "name", "institution"],
            limit: FetchLimits.poles
        },
        params
    );
}
