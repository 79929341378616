import { IReduxAction } from "../store.types";
import LangTypes from "../type/lang.types";
// French
import fr from "../../lang/fr";
// German
import de from "../../lang/de";
// English
import en from "../../lang/en";
// Spanish
import es from "../../lang/es";

import { EAvailableLang } from "../../module/constant/International.constant";
import { Constants } from "npm-hublo-toolbox";

const langs: {
    currentLang: EAvailableLang;
    fr: Constants.ILang;
    de: Constants.ILang;
    en: Constants.ILang;
    es: Constants.ILang;
} = {
    currentLang: EAvailableLang.fr,
    fr,
    de,
    en,
    es
};

const initialState: { currentLang: EAvailableLang; lang: Constants.ILang } = {
    currentLang: langs.currentLang,
    lang: langs.fr
};

export default function langReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case LangTypes.SET_LANG:
            return {
                ...state,
                // @ts-ignore
                lang: langs[action.payload],
                currentLang: action.payload
            };

        default:
            return state;
    }
}
