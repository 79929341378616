import { IMissionLang } from "../interface/Mission.interface";

export const esMission: IMissionLang = {
    allMissions: "Todas las coberturas",
    cancellationMotifForInstitution: "Motivo de cancelación - Centro sanitario",
    cancellationMotifForWorker: "Motivo de cancelación - Sustituto",
    cancellationMotifsInstitution: {
        activityReduction: "Reducción de la actividad",
        workerFindOutOfNetwork: "Sustituto encontrado fuera de Hublo ",
        institutionInterim: "ETT",
        institutionNoWorkerNeeded: "Situación manejada sin sustituto",
        institutionTypingError: "Error de entrada",
        nbHourForWorkerOverCome: "Este sustituto ha superado el número de horas permitidas ",
        findOutOfHublo: "Sustituto horas extraordinarias encontrado fuera de Hublo",
        internalOrganisation: "Reorganización interna",
        institutionOtherReason: "Otro motivo"
    },
    cancellationMotifsWorker: {
        cancelByWorker: "Cancelación por el sustituto",
        workerDidntCome: "El sustituto no vino"
    },
    cancelledFilled: "Canceladas - cubiertas",
    cancelledNotFilled: "Canceladas - sin cubrir",
    cancelRate: "Tasa de cancelación",
    createdMissions: "coberturas creadas ",
    declared: "Declaradas",
    filledNotFilledRepartition: "Distribución coberturas cubiertas/ sin cubrir",
    missionCanceledRate: "Tasa de cancelación de coberturas",
    missionCreatedNumberByService: "Número de coberturas creadas por unidad",
    missionFilledRate: "Tasa de coberturas cubiertas ",
    missionNumberEvolution: "evolución del número de coberturas",
    missionTypeRepartition: "Distribución de las coberturas por tipo ",
    outsidePole: "Fuera del servicio",
    planned: "Planificadas",
    posted: "Publicadas",
    rateOfCanceledMissions: "Tasa de coberturas canceladas ",
    unspecifiedService: "Unidad sin especificar",
    urgent: "Urgentes\n",
    urgentMissionTooltip:
        "<strong>Una misión urgente</strong> es una misión cuyo tiempo transcurrido entre <strong>la creación y el principio de la misión es menor o igual a 48h</strong>."
};
