import { IFooterLang } from "../interface/Footer.interface";

export const deFooter: IFooterLang = {
    customerPartners: "Kunden/Partner",
    solution: "Unsere Lösung",
    about: "Über uns",
    contact: "Kontakt",
    blog: "Blog",
    whitePapers: "Whitepaper",
    faq: "Häufig gestellte Fragen",
    pages: "Seiten",
    weAreConnected: "Soziale Medien",
    allRightsReserved: "Alle Rechte vorbehalten.",
    TermsOfService: "Nutzungsbedingungen",
    languages: "Sprachen",
    language: "Sprache",
    noResult: "Kein Ergebnis",
    mstaffSolution: "MSTAFF"
};
