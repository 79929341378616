import { IMission, IMissionWithDateAsMoment } from "../../../Models/Mission";
import moment from "moment";
import { IReducedInstitutionHasWorker } from "../../../Models/InstitutionHasWorker";
import { getWorkerStatusFromMission } from "../toolbox/institutionHasWorker";

export function getMissionsWithDateAsMomentFromMissions(
    missions: IMission[],
    institutionHasWorkersDict: { [key: number]: IReducedInstitutionHasWorker }
): IMissionWithDateAsMoment[] {
    return missions.map(
        (mission): IMissionWithDateAsMoment => {
            return {
                ...mission,
                beginAt: moment(mission.beginAt),
                createdAt: moment(mission.createdAt),
                workerStatus: getWorkerStatusFromMission(institutionHasWorkersDict, mission)
            };
        }
    );
}
