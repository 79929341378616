import { IReduxAction } from "../store.types";
import MissionTypes from "../type/mission.types";

const initialState: any = {
    missions: [],
    isLoadingFetchMissions: false,
    filteredMissions: [],
    isLoadingFilteredMissions: false,
    missionEvolutionMode: 0,
    missionFilledRateEvolutionMode: 0,
    cancelledMissionEvolutionMode: 0
};

export default function missionReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case MissionTypes.FETCH_MISSIONS:
            return {
                ...state,
                missions: action.payload
            };
        case MissionTypes.SET_IS_LOADING_FETCH_MISSIONS:
            return {
                ...state,
                isLoadingFetchMissions: action.payload
            };
        case MissionTypes.SET_FILTERED_MISSIONS:
            return {
                ...state,
                filteredMissions: action.payload
            };
        case MissionTypes.SET_IS_LOADING_FILTERED_MISSIONS:
            return {
                ...state,
                isLoadingFilteredMissions: action.payload
            };
        case MissionTypes.SET_MISSION_EVOLUTION_MODE:
            return {
                ...state,
                missionEvolutionMode: action.payload
            };
        case MissionTypes.SET_MISSION_FILLED_RATE_EVOLUTION_MODE:
            return {
                ...state,
                missionFilledRateEvolutionMode: action.payload
            };
        case MissionTypes.SET_CANCELLED_MISSION_EVOLUTION_MODE:
            return {
                ...state,
                cancelledMissionEvolutionMode: action.payload
            };
        default:
            return state;
    }
}
