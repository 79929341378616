import { IDashboardLang } from "../interface/Dashboard.interface";

export const enDashboard: IDashboardLang = {
    network: "Network",
    missions: "Shifts",
    HRMonitoring: "HR follow-up",
    mobilePage:
        'Page not accessible from a smartphone or tablet. Please go to the "Dashboard" section of your Hublo account on a computer to display your dashboard',
    noMissionMatchesCriteria: "No data matches your filters."
};
