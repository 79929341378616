import { IReduxAction } from "../store.types";
import MotifTypes from "../type/missionMotifCreation.types";

const initialState: any = {
    missionMotifCreations: [],
    isLoadingFetchMissionMotifCreations: false
};

export default function motifReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case MotifTypes.FETCH_MISSION_MOTIF_CREATIONS:
            return {
                ...state,
                missionMotifCreations: action.payload
            };
        case MotifTypes.SET_IS_LOADING_FETCH_MISSION_MOTIF_CREATION:
            return {
                ...state,
                isLoadingFetchMissionMotifCreations: action.payload
            };
        default:
            return state;
    }
}
