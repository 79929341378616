import { INavbarLang } from "../interface/Navbar.interface";

export const enNavbar: INavbarLang = {
    proMissions: "My shifts",
    proVacataires: "My network",
    proContracts: "My contracts",
    dashboard: "Dashboard",
    profile: "My account",
    myInstitutions: "My institutions",
    myNotifications: "My Notifications",
    myConfig: "Settings",
    logout: "Sign out",
    contractDemo: "⚡ My contracts",
    talent: "Talent"
};
