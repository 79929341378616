import AppTypes from "../type/app.types";
import { IReduxAction } from "../store.types";

export function setIsLoadingAppAction(isLoadingApp: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        return dispatch({
            type: AppTypes.SET_IS_LOADING_APP,
            payload: isLoadingApp
        });
    };
}
