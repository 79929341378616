import { IReduxAction } from "../store.types";
import SpecialtyTypes from "../type/specialty.types";
import { fetchSpecialtyById, fetchSpecialties } from "../../service/specialty/specialty.service";

export function setIsLoadingFetchSpecialtiesAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: SpecialtyTypes.SET_IS_LOADING_FETCH_SPECIALTIES,
            payload: bool
        });
    };
}

export function fetchSpecialtiesAction(
    idInstitutions: number[]
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        dispatch({
            type: SpecialtyTypes.SET_IS_LOADING_FETCH_SPECIALTIES,
            payload: true
        });
        const services = await fetchSpecialties({
            query: {
                institution: idInstitutions
            }
        });
        dispatch({
            type: SpecialtyTypes.FETCH_SPECIALTIES,
            payload: services
        });
        dispatch({
            type: SpecialtyTypes.SET_IS_LOADING_FETCH_SPECIALTIES,
            payload: false
        });
    };
}

export function setIsLoadingFetchSpecialtyByIdAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: SpecialtyTypes.SET_IS_LOADING_FETCH_SPECIALTY_BY_ID,
            payload: bool
        });
    };
}

export function fetchSpecialtyByIdAction(id: number): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const service = await fetchSpecialtyById(id);
        dispatch({
            type: SpecialtyTypes.FETCH_SPECIALTY,
            payload: service
        });
    };
}
