import AuthTypes from "../type/auth.types";
import { fetchMe } from "../../service/auth/user.service";
import { IReduxAction } from "../store.types";

export function fetchMeAction(): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const user = await fetchMe();
        return dispatch({
            type: AuthTypes.FETCH_ME,
            payload: user
        });
    };
}
