import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducer";
const initialState: any = {};
const middlewares = [thunk];
const emptyMiddleware = (): any => (next: any): any => (action: any): any => {
    return next(action);
};
const devTools =
    process.env.NODE_ENV !== "production"
        ? //
          // @ts-ignore
          window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
        : applyMiddleware(emptyMiddleware);
const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middlewares), devTools));

export default store;
