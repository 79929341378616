import { IFilterLang } from "../interface/Filter.interface";

export const frFilter: IFilterLang = {
    institution: "Établissement / Site",
    job: "métier",
    period: "période",
    service: "service",
    pole: "pôle",
    lastFourWeeks: "4 dernières semaines",
    lastSixMonths: "6 derniers mois",
    lastTwelveMonths: "12 derniers mois",
    lastYear: "Année dernière",
    onGoingYear: "Année en cours"
};
