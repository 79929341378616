export interface ScopedPermissions {
    idScope: string;
    permissions: string[];
    permissionGroups: string[];
}

export enum Permission {
    ACCESS_SETTINGS = "accessSettings",
    SEE_MISSION_OUTSIDE_SERVICE = "seeMissionOutsideService",
    SEE_PRO_INSTITUTION_PAGE = "seeProInstitutionPage",
    SEE_PRO_INSTITUTION_CONFIG_PAGE = "seeProInstitutionConfigPage",
    SEE_STATISTICS_PAGE = "seeStatisticsPage"
}

type Scope = string;
export type UserPermissions = Record<Scope, Permission[]>;
