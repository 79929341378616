import { ICommonLang } from "../interface/Common.interface";

export const deCommon: ICommonLang = {
    search: "Suche",
    resetFilters: "Filter zurücksetzen",
    next: "weiter",
    previous: "zurück",
    notSpecifiedMale: "unbestimmt",
    notSpecifiedFemale: "unbestimmt",
    download: "download",
    new: "neu",
    anErrorOccured: "Ein Fehler ist aufgetreten",
    maintenanceBanner:
        "In Hublo kommt es zu Verzögerungen und wir bemühen uns, diese zu beheben. Wir entschuldigen uns für die Unannehmlichkeiten, die dadurch entstehen."
};
