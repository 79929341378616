import { IDashboardLang } from "../interface/Dashboard.interface";

export const frDashboard: IDashboardLang = {
    network: "Réseau",
    missions: "Missions",
    HRMonitoring: "Suivi rh",
    mobilePage:
        'Page non accessible depuis un smartphone ou une tablette. Veuillez vous rendre dans la section "Tableau de Bord" de votre compte Hublo sur un ordinateur pour afficher votre tableau de bord',
    noMissionMatchesCriteria: "Aucune donnée ne correspond à vos filtres."
};
