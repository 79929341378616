import { IReduxAction } from "../store.types";
import InstitutionGroupTypes from "../type/institutionGroup.types";

const initialState: any = {
    institutionGroups: {},
    institutionsGroupsGrouping: {},
    institutionsGroupsCluster: {},
    isLoadingInstitutionGroups: false,
    isLoadingInstitutionsGroupsGrouping: false,
    isLoadingInstitutionsGroupsCluster: false
};

export default function institutionGroupReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InstitutionGroupTypes.FETCH_INSTITUTION_GROUPS:
            return {
                ...state,
                institutionGroups: action.payload
            };
        case InstitutionGroupTypes.FETCH_INSTITUTIONS_GROUPS_CLUSTER:
            return {
                ...state,
                institutionsGroupsCluster: action.payload
            };
        case InstitutionGroupTypes.FETCH_INSTITUTIONS_GROUPS_GROUPING:
            return {
                ...state,
                institutionsGroupsGrouping: action.payload
            };
        case InstitutionGroupTypes.SET_IS_LOADING_FETCH_INSTITUTION_GROUPS:
            return {
                ...state,
                isLoadingInstitutionGroups: action.payload
            };
        case InstitutionGroupTypes.SET_IS_LOADING_FETCH_INSTITUTIONS_GROUPS_CLUSTER:
            return {
                ...state,
                isLoadingInstitutionsGroupsCluster: action.payload
            };
        case InstitutionGroupTypes.SET_IS_LOADING_FETCH_INSTITUTIONS_GROUPS_GROUPING:
            return {
                ...state,
                isLoadingInstitutionsGroupsGrouping: action.payload
            };
        default:
            return state;
    }
}
