import { IDashboardLang } from "../interface/Dashboard.interface";

export const esDashboard: IDashboardLang = {
    HRMonitoring: "Seguimiento de RR. HH.",
    missions: "Coberturas ",
    mobilePage:
        'No se puede acceder a esta página web desde un smartphone o una tablet.  Diríjase a la sección "Cuadro de mando" de su cuenta Hublo en un ordenador para ver su cuadro de mando.',
    network: "red ",
    noMissionMatchesCriteria: "Ninguna cobertura corresponde a su búsqueda "
};
