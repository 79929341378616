import { IReduxAction } from "../store.types";
import LangTypes from "../type/lang.types";
import { EAvailableLang } from "../../module/constant/International.constant";
import { frPageDescription } from "../../lang/fr/pageDescription";
import { dePageDescription } from "../../lang/de/pageDescription";
import { enPageDescription } from "../../lang/en/pageDescription";
import ServiceTypes from "../type/service.types";
import FiltersTypes from "../type/filter.types";
import { resetEmptyServiceInstance } from "../../module/constant/EmptyObject.constant";

function setTitleDescription(lang: EAvailableLang): void {
    document.title = enPageDescription.title;
    document
        .getElementsByName("description")
        .forEach((e): void => e.setAttribute("content", enPageDescription.description));
    if (lang === EAvailableLang.fr) {
        document.title = frPageDescription.title;
        document
            .getElementsByName("description")
            .forEach((e): void => e.setAttribute("content", frPageDescription.description));
    } else if (lang === EAvailableLang.de) {
        document.title = dePageDescription.title;
        document
            .getElementsByName("description")
            .forEach((e): void => e.setAttribute("content", dePageDescription.description));
    }
}

export function setLangAction(
    lang: EAvailableLang = EAvailableLang.fr
): (dispatch: (action: IReduxAction) => void) => void {
    return (dispatch: (action: IReduxAction) => void): void => {
        require("moment/locale/".concat(lang !== "en" ? lang : "en-gb"));
        setTitleDescription(lang);
        localStorage.setItem("lang", lang);
        dispatch({
            type: LangTypes.SET_LANG,
            payload: lang
        });
        const services = resetEmptyServiceInstance();
        dispatch({
            type: ServiceTypes.FETCH_SERVICES,
            payload: services
        });
        dispatch({
            type: FiltersTypes.SET_FILTERED_SERVICES,
            payload: services
        });
    };
}
