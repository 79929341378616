import { IReduxAction } from "../store.types";
import WorkerTypes from "../type/worker.types";

const initialState: any = {
    workersThatWantToJoinNetworkCount: 0,
    workersRequestingJobsCount: 0,
    workersWithProfileThatMayInterestCount: 0,
    isLoadingFetchWorkersThatWantToJoinNetworkCount: false,
    isLoadingFetchWorkersRequestingJobsCount: false,
    isLoadingFetchWorkersWithProfileThatMayInterestCount: false
};
export default function workersReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case WorkerTypes.FETCH_WORKERS_WANT_JOIN_NETWORK_COUNT:
            return {
                ...state,
                workersThatWantToJoinNetworkCount: action.payload
            };
        case WorkerTypes.FETCH_WORKERS_REQUESTING_JOBS_COUNT:
            return {
                ...state,
                workersRequestingJobsCount: action.payload
            };
        case WorkerTypes.FETCH_WORKERS_PROFILE_THAT_MAY_INTEREST_YOU_COUNT:
            return {
                ...state,
                workersWithProfileThatMayInterestCount: action.payload
            };
        case WorkerTypes.SET_IS_LOADING_FETCH_WORKERS_WANT_JOIN_NETWORK_COUNT:
            return {
                ...state,
                isLoadingFetchWorkersThatWantToJoinNetworkCount: action.payload
            };
        case WorkerTypes.SET_IS_LOADING_FETCH_WORKERS_REQUESTING_JOBS_COUNT:
            return {
                ...state,
                isLoadingFetchWorkersRequestingJobsCount: action.payload
            };
        case WorkerTypes.SET_IS_LOADING_FETCH_WORKERS_PROFILE_THAT_MAY_INTEREST_YOU_COUNT:
            return {
                ...state,
                isLoadingFetchWorkersWithProfileThatMayInterestCount: action.payload
            };
        default:
            return state;
    }
}
