import { IQueryParams } from "medgo-query-generator";
import { PossibleApis, PossibleFetchs } from "../../module/constant/Api.constants";
import { postFetchFactory } from "../../module/factory/postFetch.factory";
import { IInstitutionHasAdmin } from "../../../Models/InstitutionHasAdmin";

const AdminInInstitutionDefaultSelect = ["id", "admin", "institution"];

export async function fetchInstitutionHasAdmins(query: IQueryParams): Promise<IInstitutionHasAdmin[]> {
    const adminInInstitutions = await postFetchFactory<IInstitutionHasAdmin>(
        PossibleFetchs.institutionHasAdmin,
        PossibleApis.serviceApi,
        2
    )(
        {
            select: AdminInInstitutionDefaultSelect
        },
        query
    );
    return adminInInstitutions;
}
