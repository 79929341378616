export enum PossibleFetchs {
    admins = "admin",
    institutions = "institution",
    services = "service",
    jobs = "job",
    poles = "pole",
    workers = "worker",
    specialties = "specialty",
    level = "level",
    institutionHasWorkers = "institutionhasworker",
    institutionOptions = "institution/:id/options",
    institutionGroups = "institutionGroup",
    missions = "mission",
    missionMotifCreations = "missionmotifcreation",
    institutionHasAdmin = "institution-has-admin/fetch",
    signUpCode = "signup-code/fetch"
}

export enum PossibleUpdates {
    adminInfo = "admin-info"
}

export const PossibleApis = {
    serviceApi: process.env.REACT_APP_SERVICE_API_URL || ""
};

export const FetchLimits = {
    institutionHasWorkers: 999999,
    jobs: 9999,
    services: 999,
    specialties: 999,
    poles: 999,
    missions: 999999,
    missionMotifCreations: 999,
    institutions: 100
};
