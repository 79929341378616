import { deCommon } from "./common";
import { deWorker } from "./worker";
import { deNavbar } from "./navbar";
import { deDashboard } from "./dashboard";
import { deFilter } from "./filter";
import { deNetwork } from "./network";
import { deHRMonitoring } from "./hrMonitoring";
import { deMission } from "./mission";
import { deFooter } from "./footer";
export default Object.assign(
    {},
    deCommon,
    deWorker,
    deNavbar,
    deDashboard,
    deFilter,
    deNetwork,
    deHRMonitoring,
    deMission,
    deFooter
);
