import { IReduxAction } from "../store.types";
import LevelTypes from "../type/level.types";

const initialState: any = {
    level: null
};

export default function levelReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case LevelTypes.FETCH_LEVEL:
            return {
                ...state,
                level: action.payload
            };

        default:
            return state;
    }
}
