import { Moment } from "moment";
import { IReducedInstitutionHasWorker } from "../../../Models/InstitutionHasWorker";
import { isNull, isNullOrUndefined } from "util";
import { IMission } from "../../../Models/Mission";
import {
    STATUTS,
    OTHER_STATUS,
    STATUT_INTERIM_WORKER,
    STATUT_POOL_WORKER
} from "../constant/InstitutionHasWorker.constants";
import { isMissionFilled } from "./mission";

export function hasWorkerExpectedJobs(
    institutionHasWorker: IReducedInstitutionHasWorker,
    institutions: number[],
    jobs: number[]
): boolean {
    const institutionIndexes = Object.entries(institutionHasWorker.institution)
        .filter((e): boolean => institutions.indexOf(e[1]) !== -1)
        .map((e): number => +e[0]);
    return (
        jobs.length === 0 ||
        jobs.filter(
            (job: number): boolean =>
                institutionHasWorker.job
                    .filter((e, i): boolean => institutionIndexes.indexOf(i) !== -1)
                    .reduce((acc, current): number[] => acc.concat(current), [])
                    .indexOf(job) !== -1
        ).length > 0
    );
}

export function isWorkerInInstitutionNetworkBeforeDate(
    institutionHasWorker: IReducedInstitutionHasWorker,
    institutions: number[],
    date: Moment
): boolean {
    const institutionIndexes = Object.entries(institutionHasWorker.institution)
        .filter((e): boolean => institutions.indexOf(e[1]) !== -1)
        .map((e): number => +e[0]);
    return (
        institutionHasWorker.createdAt
            .filter((_, i): boolean => institutionIndexes.indexOf(i) !== -1)
            .map((e): boolean => e.isBefore(date))
            .reduce((acc, current): boolean => acc || current, false) &&
        (institutionHasWorker.isDeletedFromNetwork
            .filter((_, i): boolean => institutionIndexes.indexOf(i) !== -1)
            .indexOf(false) !== -1 ||
            institutionHasWorker.deletedAt
                .filter((_, i): boolean => institutionIndexes.indexOf(i) !== -1)
                .indexOf(null) !== -1 ||
            institutionHasWorker.deletedAt
                .filter((_, i): boolean => institutionIndexes.indexOf(i) !== -1)
                .map((e): boolean => !isNull(e) && e.isSameOrAfter(date))
                .reduce((acc, current): boolean => acc || current, false))
    );
}

export function hasWorkerExpectedInstitutions(
    institutionHasWorker: IReducedInstitutionHasWorker,
    institutions: number[]
): boolean {
    return (
        institutions.filter((institution): boolean => institutionHasWorker.institution.indexOf(institution) !== -1)
            .length > 0
    );
}

export function hasWorkerExpectedStatut(
    institutionHasWorker: IReducedInstitutionHasWorker,
    institutions: number[],
    statut: number
): boolean {
    const statusInInstitutions: number[] = [];
    institutionHasWorker.institution.forEach((institution: number, index: number): void => {
        if (institutions.includes(institution)) {
            statusInInstitutions.push(institutionHasWorker.statut[index]);
        }
    });
    return statusInInstitutions.includes(statut);
}

export function getWorkerStatusFromMission(
    institutionHasWorkersDict: { [key: number]: IReducedInstitutionHasWorker },
    mission: IMission
): number | null {
    if (!isNullOrUndefined(mission.workerStatusAtMissionProvided)) {
        const foundStatus = STATUTS.concat(OTHER_STATUS).find(
            (e): boolean => e === mission.workerStatusAtMissionProvided
        );
        if (foundStatus) {
            return foundStatus;
        }
    }
    if (mission.worker && institutionHasWorkersDict[mission.worker] && institutionHasWorkersDict[mission.worker]) {
        const institutionIndex = institutionHasWorkersDict[mission.worker].institution.findIndex(
            (e): boolean => e === mission.institution
        );
        return institutionHasWorkersDict[mission.worker].statut[institutionIndex];
    } else if (!isNullOrUndefined(mission.interimAgencyCandidate)) {
        return STATUT_INTERIM_WORKER;
    } else if (isMissionFilled(mission) && isNullOrUndefined(mission.worker)) {
        return STATUT_POOL_WORKER;
    }
    return null;
}
