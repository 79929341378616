import { fetchByIdFactory } from "../../module/factory/fetchById.factory";
import { IService } from "../../../Models/Service";
import { fetchFactory } from "../../module/factory/fetch.factory";
import { IQueryParams } from "medgo-query-generator";
import { PossibleFetchs, PossibleApis, FetchLimits } from "../../module/constant/Api.constants";

export async function fetchServiceById(id: number): Promise<IService> {
    return fetchByIdFactory<IService>(PossibleFetchs.services, PossibleApis.serviceApi)(id, {
        select: ["id", "name", "institution", "pole", "isOnForCreatingMission"]
    });
}
export async function fetchServices(params: IQueryParams): Promise<IService[]> {
    return fetchFactory<IService>(PossibleFetchs.services, PossibleApis.serviceApi)(
        {
            select: ["id", "name", "institution", "pole", "isOnForCreatingMission"],
            limit: FetchLimits.services
        },
        params
    );
}
