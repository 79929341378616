import { esCommon } from "./common";
import { esWorker } from "./worker";
import { esNavbar } from "./navbar";
import { esDashboard } from "./dashboard";
import { esFilter } from "./filter";
import { esNetwork } from "./network";
import { esHRMonitoring } from "./hrMonitoring";
import { esMission } from "./mission";
import { esFooter } from "./footer";
export default Object.assign(
    {},
    esCommon,
    esWorker,
    esNavbar,
    esDashboard,
    esFilter,
    esNetwork,
    esHRMonitoring,
    esMission,
    esFooter
);
