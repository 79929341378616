import { IReduxAction } from "../store.types";
import FiltersTypes from "../type/filter.types";

const initialState: any = {
    selectedInstitutions: [],
    selectedJobs: [],
    filteredServices: [],
    selectedServices: [],
    filteredPoles: [],
    selectedPoles: [],
    selectedPeriod: null
};

export default function FiltersReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case FiltersTypes.SET_SELECTED_INSTITUTIONS:
            return {
                ...state,
                selectedInstitutions: action.payload
            };
        case FiltersTypes.SET_SELECTED_JOBS:
            return {
                ...state,
                selectedJobs: action.payload
            };
        case FiltersTypes.SET_SELECTED_PERIOD:
            return {
                ...state,
                selectedPeriod: action.payload
            };
        case FiltersTypes.SET_FILTERED_SERVICES:
            return {
                ...state,
                filteredServices: action.payload
            };
        case FiltersTypes.SET_SELECTED_SERVICES:
            return {
                ...state,
                selectedServices: action.payload
            };
        case FiltersTypes.SET_FILTERED_POLES:
            return {
                ...state,
                filteredPoles: action.payload
            };
        case FiltersTypes.SET_SELECTED_POLES:
            return {
                ...state,
                selectedPoles: action.payload
            };
        default:
            return state;
    }
}
