import { IReduxAction } from "../store.types";
import InstitutionOptionTypes from "../type/institutionOption.types";
import {
    fetchInstitutionOptions,
    fetchInstitutionsOptions
} from "../../service/institutionOption/institutionOption.service";

export function setIsLoadingFetchInstitutionOptionsAction(
    bool: boolean
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionTypes.SET_IS_LOADING_FETCH_INSTITUTION_OPTIONS,
            payload: bool
        });
    };
}

export function fetchInstitutionOptionsAction(
    idInstitution: number
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        dispatch({
            type: InstitutionOptionTypes.SET_IS_LOADING_FETCH_INSTITUTION_OPTIONS,
            payload: true
        });
        const institutionOption = await fetchInstitutionOptions(idInstitution);
        dispatch({
            type: InstitutionOptionTypes.FETCH_INSTITUTION_OPTIONS,
            payload: institutionOption
        });
        dispatch({
            type: InstitutionOptionTypes.SET_IS_LOADING_FETCH_INSTITUTION_OPTIONS,
            payload: false
        });
    };
}

export function setIsLoadingFetchInstitutionsOptionsAction(
    bool: boolean
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionOptionTypes.SET_IS_LOADING_FETCH_INSTITUTIONS_OPTIONS,
            payload: bool
        });
    };
}

export function fetchInstitutionsOptionsAction(
    idInstitutions: number[]
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        dispatch({
            type: InstitutionOptionTypes.SET_IS_LOADING_FETCH_INSTITUTIONS_OPTIONS,
            payload: true
        });
        const institution = await fetchInstitutionsOptions(idInstitutions);
        dispatch({
            type: InstitutionOptionTypes.FETCH_INSTITUTIONS_OPTIONS,
            payload: institution
        });
        dispatch({
            type: InstitutionOptionTypes.SET_IS_LOADING_FETCH_INSTITUTIONS_OPTIONS,
            payload: false
        });
    };
}
