enum MissionTypes {
    FETCH_MISSIONS = "FETCH_MISSIONS",
    SET_IS_LOADING_FETCH_MISSIONS = "SET_IS_LOADING_FETCH_MISSIONS",
    SET_FILTERED_MISSIONS = "SET_FILTERED_MISSIONS",
    SET_IS_LOADING_FILTERED_MISSIONS = "SET_IS_LOADING_FILTERED_MISSIONS",
    SET_MISSION_EVOLUTION_MODE = "SET_MISSION_EVOLUTION_MODE",
    SET_MISSION_FILLED_RATE_EVOLUTION_MODE = "SET_MISSION_FILLED_RATE_EVOLUTION_MODE",
    SET_CANCELLED_MISSION_EVOLUTION_MODE = "SET_CANCELLED_MISSION_EVOLUTION_MODE"
}

export default MissionTypes;
