import { INavbarLang } from "../interface/Navbar.interface";

export const esNavbar: INavbarLang = {
    contractDemo: "⚡ Mis contratos ",
    dashboard: "cuadro de mando",
    logout: "cerrar sesión ",
    myConfig: "ajustes",
    myInstitutions: "Mis centros sanitarios",
    myNotifications: "mis notificaciones ",
    proContracts: "Mis contratos",
    profile: "Mi cuenta",
    proMissions: "Mis coberturas ",
    proVacataires: "Mi red",
    talent: "Talent"
};
