import AxiosInstance from "../axios/axios.instance";
import { PossibleApis, PossibleFetchs } from "../constant/Api.constants";
import { IQueryParams } from "medgo-query-generator";
import generateParams from "../toolbox/customParamGenerator";

export function fetchByIdFactory<T>(
    modalName: PossibleFetchs,
    api: string = PossibleApis.serviceApi
): (id: number, defaultParams: IQueryParams, queryParams?: IQueryParams) => Promise<T> {
    return async (id: number, defaultParams: IQueryParams, queryParams?: IQueryParams): Promise<T> => {
        const params = Object.assign({}, defaultParams, queryParams ? queryParams : {});
        const res = await AxiosInstance.get(
            api + "/api/v1/".concat(modalName, "/", id.toString(), "?", generateParams(params))
        );
        return res.data[0] ? res.data[0] : res.data;
    };
}
export function fetchByIdSubFieldFactory<T>(
    modalName: PossibleFetchs,
    api: string = PossibleApis.serviceApi
): (id: number, defaultParams: IQueryParams, queryParams?: IQueryParams) => Promise<T> {
    return async (id: number, defaultParams: IQueryParams, queryParams?: IQueryParams): Promise<T> => {
        const params = Object.assign({}, defaultParams, queryParams ? queryParams : {});
        const res = await AxiosInstance.get(
            api + "/api/v1/".concat(modalName.replace(":id", id.toString()), "?", generateParams(params))
        );
        return res.data;
    };
}
