import { ICommonLang } from "../interface/Common.interface";

export const enCommon: ICommonLang = {
    search: "Search",
    resetFilters: "Reset filters",
    next: "Next",
    previous: "Previous",
    notSpecifiedMale: "Unspecified",
    notSpecifiedFemale: "Unspecified",
    download: "Download",
    new: "New",
    anErrorOccured: "An error has occurred",
    maintenanceBanner:
        "Hublo is experiencing slowdowns and we are doing everything we can to fix this. We are sorry for the inconvenience."
};
