import AxiosInstance from "../axios/axios.instance";
import { PossibleFetchs, PossibleApis } from "../constant/Api.constants";
import { IQueryParams } from "medgo-query-generator";
import generateParams from "../toolbox/customParamGenerator";

export function fetchFactory<T>(
    modalName: PossibleFetchs,
    api: string = PossibleApis.serviceApi
): (defaultParams: IQueryParams, queryParams?: IQueryParams, queryUrlParams?: any) => Promise<T[]> {
    return async (defaultParams: IQueryParams, queryParams?: IQueryParams, queryUrlParams?: any): Promise<T[]> => {
        const params = Object.assign({}, defaultParams, queryParams ? queryParams : {});
        const queryUrlKeys = queryUrlParams ? Object.keys(queryUrlParams) : [];

        const res = await AxiosInstance.get(
            api +
                "/api/v1/".concat(
                    modalName,
                    "?",
                    generateParams(params),
                    queryUrlKeys.length > 0
                        ? "&".concat(queryUrlKeys.map((e): string => e + "=" + queryUrlParams[e]).join("&"))
                        : ""
                )
        );
        return res.data;
    };
}

export function fetchNormalizedFactory<T>(
    modalName: PossibleFetchs,
    api: string = PossibleApis.serviceApi
): (defaultParams: IQueryParams, queryParams?: IQueryParams) => Promise<T> {
    return async (defaultParams: IQueryParams, queryParams?: IQueryParams): Promise<T> => {
        const params = Object.assign({}, defaultParams, queryParams ? queryParams : {});
        const res = await AxiosInstance.get(api + "/api/v1/".concat(modalName, "?", generateParams(params)));
        return res.data.reduce((p: any, c: any): any => {
            p[c.id ? c.id : -1] = c;
            return p;
        }, {});
    };
}
