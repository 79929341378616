import { INetworkLang } from "../interface/Network.interface";

export const deNetwork: INetworkLang = {
    pendingRequests: "Zu bearbeiten",
    inNetworkWorkers: "Pflegefachkräfte innerhalb des Netzwerks",
    workerLegend1: "Mitarbeiter",
    workerLegend2: "",
    contractorLegend1: "Aushilfskräfte",
    contractorLegend2: "",
    interimLegend1: "Aushilfskräfte",
    interimLegend2: "",
    joinNetworkRequest: "Beitrittsanfrage(n)",
    skillsValidationRequest: "Kompetenzanfrage(n)",
    profilesThanMayInterestYou: "Profil, welches Sie interessieren könnte",
    networkStatus: "Status Ihres Netzwerkes",
    networkEvolution: "Entwicklung Ihres Netzwerkes",
    workersOrAgents: "Mitarbeiter",
    contractors: "Aushilfskräfte",
    agencyWorkers: "Aushilfskräfte",
    allNetwork: "Gesamtes Netzwerk",
    activeNetwork: "Aktives Netzwerk",
    onGoing: "Laufend",
    activeUserTooltip: `<strong>Eine Pflegefachkraft ist aktiv</strong>, wenn sie innerhalb der letzten drei Monate oder im kommenden Monat mindestens eine Schicht übernommen,
        sich auf eine Schicht beworben oder ihre Verfügbarkeiten angegeben/aktualisiert hat.`,
    willBeAvailableInTheFuture: "In Entwicklung"
};
