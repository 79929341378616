import { IMissionLang } from "../interface/Mission.interface";

export const enMission: IMissionLang = {
    missionNumberEvolution: "evolution of the number of shifts",
    allMissions: "All shifts",
    urgent: "Urgent",
    createdMissions: "Shifts created",
    urgentMissionTooltip:
        "<strong>An urgent shift</strong> is a shift whose <strong>duration between the creation of the shift</strong> (or the validation of the need) <strong> and the beginning of the shift is less than or equal to 48h</strong>.",
    missionFilledRate: "Rate of shifts staffed",
    posted: "Posted",
    declared: "Declared",
    planned: "Planned",
    missionTypeRepartition: "Breakdown of shifts by type",
    missionCreatedNumberByService: "Number of shifts created per department",
    missionCanceledRate: "Shift cancellation rate",
    cancelRate: "Cancellation rate",
    filledNotFilledRepartition: "Breakdown provided / not provided",
    rateOfCanceledMissions: "Rate of shift cancellations",
    cancellationMotifsInstitution: {
        activityReduction: "Reduction of activity",
        workerFindOutOfNetwork: "Replacement found outside Hublo",
        institutionInterim: "Interim",
        institutionNoWorkerNeeded: "Managed situation without replacement",
        institutionTypingError: "Input Error",
        nbHourForWorkerOverCome: "Authorized number of hours exceeded for this replacement",
        findOutOfHublo: "Overtime replacement found outside Hublo",
        internalOrganisation: "Internal reorganization",
        institutionOtherReason: "Other reason"
    },
    cancellationMotifsWorker: {
        cancelByWorker: "Cancellation by the replacement",
        workerDidntCome: "The replacement did not show up"
    },
    cancellationMotifForInstitution: "Reason for Cancellation - Institution",
    cancellationMotifForWorker: "Reason for Cancellation - Replacement",
    cancelledFilled: "Cancelled - filled",
    cancelledNotFilled: "Cancelled - not filled",
    outsidePole: "Outside pole",
    unspecifiedService: "Unspecified department"
};
