export enum EAvailableLang {
    "fr" = "fr",
    "en" = "en",
    "de" = "de",
    "es" = "es"
}

interface ILangConstant {
    name: string;
    lang: EAvailableLang;
    idLanguage: number;
    countryFlag: string;
}

export const LangConstants: ILangConstant[] = [
    {
        countryFlag: "🇫🇷",
        idLanguage: 1,
        lang: EAvailableLang.fr,
        name: "Français"
    },
    {
        countryFlag: "🇬🇧",
        idLanguage: 2,
        lang: EAvailableLang.en,
        name: "English"
    },
    {
        countryFlag: "🇩🇪",
        idLanguage: 3,
        lang: EAvailableLang.de,
        name: "Deutsch"
    },
    {
        countryFlag: "🇪🇸",
        idLanguage: 4,
        lang: EAvailableLang.es,
        name: "Spanish"
    }
];

export const DefaultLanguage = LangConstants[0];
