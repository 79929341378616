import React from "react";
import "../../scss/component/LoadingContainer.scss";
export default function LoadingContainer({
    isLoading,
    children,
    isFullPage = true
}: {
    isLoading: boolean;
    children: JSX.Element[] | JSX.Element;
    isFullPage?: boolean;
}): JSX.Element {
    return (
        <div>
            {isLoading ? <div className={"loader ".concat(isFullPage ? "full-page-loader" : "inline-loader")} /> : null}
            <div className={"loading-element " + (isLoading ? "onload" : "")}>{children}</div>
        </div>
    );
}
