import { IReduxAction } from "../store.types";
import AuthTypes from "../type/auth.types";

const initialState: any = {
    user: {},
    token: ""
};
export default function authReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case AuthTypes.FETCH_ME:
            return {
                ...state,
                user: action.payload
            };
        default:
            return state;
    }
}
