import { IQueryParams } from "medgo-query-generator";
import { fetchFactory } from "../../module/factory/fetch.factory";
import { PossibleFetchs, PossibleApis } from "../../module/constant/Api.constants";
import { IAdmin } from "../../../Models/Admin";

const defaultSelect = ["id", "idLanguage"];

export async function fetchAdmin(params: IQueryParams): Promise<IAdmin[]> {
    return fetchFactory<IAdmin>(PossibleFetchs.admins, PossibleApis.serviceApi)(
        {
            select: defaultSelect
        },
        params
    );
}
