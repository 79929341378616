import { INavbarLang } from "../interface/Navbar.interface";

export const deNavbar: INavbarLang = {
    proMissions: "Meine Schichten",
    proVacataires: "Mein Netzwerk",
    proContracts: "Meine Verträge",
    dashboard: "Auswertung",
    profile: "Profile",
    myInstitutions: "Einrichtungen",
    myNotifications: "Benachrichtigungen",
    myConfig: "Konfiguration",
    logout: "Logout",
    contractDemo: "⚡ Meine Verträge",
    talent: "Talent"
};
