import { AxiosResponse } from "axios";
import jwt from "jsonwebtoken";
import { stringify } from "querystring";

import Axios from "../axios/axios.instance";

export const refreshToken = async (payload: any): Promise<AxiosResponse<{ token: string }>> => {
    try {
        return await Axios.get(`${process.env.REACT_APP_SERVICE_AUTH_URL}/token/refresh?${stringify(payload)}`);
    } catch (error) {
        if (payload.hasOwnProperty("adminId")) {
            console.error(
                `An error has occured in refreshToken for admin ${payload.adminId} with payload: ${JSON.stringify(
                    payload
                )}: ${error}`
            );
        } else {
            console.error(
                `An error has occured in refreshToken for worker ${payload.userId} with payload: ${JSON.stringify(
                    payload
                )}: ${error}`
            );
        }
        throw new Error("anErrorOccured");
    }
};

export type UserType = "worker" | "admin";

export interface Token {
    user: {
        id: number;
        idInstitutions?: number[];
        tokenValidationNumber?: number;
        idLanguage?: number;
    };
    userType: UserType;
    userId?: number;
    exp?: number;
    iat?: number;
    timestamp?: number;
    isRefresh?: boolean;
}

export const decodeToken = (token: string): Token => jwt.decode(token) as Token;
