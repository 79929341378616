import { IFilterLang } from "../interface/Filter.interface";

export const deFilter: IFilterLang = {
    institution: "Einrichtung",
    job: "Beruf",
    period: "Periode",
    service: "Station",
    pole: "Bereich",
    lastFourWeeks: "letzten 4 Wochen",
    lastSixMonths: "letzten 6 Monate",
    lastTwelveMonths: "letzten 12 Monate",
    lastYear: "letztes Jahr",
    onGoingYear: "laufendes Jahr"
};
