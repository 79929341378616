import InstitutionHasWorkerTypes from "../type/institutionHasWorker.types";
import { IReduxAction } from "../store.types";
import { IQueryParams } from "medgo-query-generator";
import { fetchInstitutionHasWorkers } from "../../service/institutionHasWorker/institutionHasWorker.service";
import { IInstitutionHasWorker, IReducedInstitutionHasWorker } from "../../../Models/InstitutionHasWorker";
import { getInstitutionHasWorkersGroupedByWorkerAndInstitution } from "../../module/formatter/institutionHasWorker";

export function fetchInstitutionHasWorkersAction(
    params: IQueryParams = {}
): (dispatch: (x: IReduxAction) => void) => Promise<{ [key: number]: IReducedInstitutionHasWorker }> {
    return async (dispatch: (x: IReduxAction) => void): Promise<{ [key: number]: IReducedInstitutionHasWorker }> => {
        dispatch({
            type: InstitutionHasWorkerTypes.SET_IS_LOADING_FETCH_INSTITUTION_HAS_WORKERS,
            payload: true
        });
        let institutionHasWorkers: IInstitutionHasWorker[] = [];
        let fetchedInstitutionHasWorkers: any[] | null = null;
        let skip = 0;
        const step = 5000;
        while (
            fetchedInstitutionHasWorkers === null ||
            // @ts-ignore
            (Array.isArray(fetchedInstitutionHasWorkers) && fetchedInstitutionHasWorkers.length > 0)
        ) {
            fetchedInstitutionHasWorkers = await fetchInstitutionHasWorkers({
                ...params,
                limit: step,
                skip
            });
            institutionHasWorkers = institutionHasWorkers.concat(fetchedInstitutionHasWorkers);
            skip += step;
        }
        const institutionHasWorkersDict = getInstitutionHasWorkersGroupedByWorkerAndInstitution(institutionHasWorkers);
        dispatch({
            type: InstitutionHasWorkerTypes.FETCH_INSTITUTION_HAS_WORKERS,
            payload: Object.values(institutionHasWorkersDict)
        });
        dispatch({
            type: InstitutionHasWorkerTypes.SET_IS_LOADING_FETCH_INSTITUTION_HAS_WORKERS,
            payload: false
        });
        return institutionHasWorkersDict;
    };
}

export function setIsLoadingFetchInstitutionHasWorkersAction(
    bool: boolean
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        return dispatch({
            type: InstitutionHasWorkerTypes.SET_IS_LOADING_FETCH_INSTITUTION_HAS_WORKERS,
            payload: bool
        });
    };
}
