import { IReduxAction } from "../store.types";
import AdminTypes from "../type/admin.types";

const initialState: any = {
    admin: {}
};

export default function authReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case AdminTypes.FETCH_ADMIN:
            return {
                ...state,
                admin: action.payload
            };
        default:
            return state;
    }
}
