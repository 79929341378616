import { IHRMonitoringLang } from "../interface/HRMonitoring.interface";

export const enHRMonitoring: IHRMonitoringLang = {
    nbOfWorkingHoursFulfilled: "Number of hours completed by type of replacement",
    workerOrAgent: "Employee - Facility Officer",
    temporaryWorkerOrContractor: "Internal contractor - Contract worker",
    interimOrAgencyContractor: "Interim - Vacant Agency",
    pool: "Pool",
    total: "Total",
    weekBeginningOnXXX: "Week of __VAR",
    missionMotifDistribution: "Breakdown of reasons for replacement",
    absenteeism: "Absenteeism",
    needInReiforcement: "Need in reinforcement",
    vacantPosition: "Vacant position",
    vacation: "Vacation",
    other: "Other",
    institutionAttractiveness: "Institution attractiveness",
    institutionAttractivenessTooltip:
        "The<strong> institution attractiveness</strong> corresponds to the proportion of missions filled in relation to the total number of missions posted in this establishment.",
    poleAttractiveness: "Pole attractiveness",
    poleAttractivenessTooltip:
        "The<strong> pole attractiveness</strong> corresponds to the proportion of missions filled in relation to the total number of missions posted in this cluster.",
    serviceAttractiveness: "Department attractiveness",
    serviceAttractivenessTooltip:
        "The<strong> department attractiveness</strong> corresponds to the proportion of missions filled in relation to the total number of missions posted in this department.",
    hourFirstLetter: "h",
    notEnoughMissionsForThisInstitution: "Not enough missions for this establishment (5 missions minimum)",
    notEnoughMissionsForThisPole: "Not enough missions for this cluster (5 missions minimum)",
    notEnoughMissionsForThisService: "Not enough missions for this department (5 missions minimum)"
};
