import MissionTypes from "../type/mission.types";
import { IReduxAction } from "../store.types";
import { fetchMissions } from "../../service/mission/mission.service";
import { IQueryParams } from "medgo-query-generator";
import { IMission, IMissionWithDateAsMoment } from "../../../Models/Mission";
import { getMissionsWithDateAsMomentFromMissions } from "../../module/formatter/mission";
import { IReducedInstitutionHasWorker } from "../../../Models/InstitutionHasWorker";

export function fetchMissionsAction(
    params: IQueryParams = {},
    institutionHasWorkersDict: { [key: number]: IReducedInstitutionHasWorker }
): (dispatch: (x: IReduxAction) => void) => Promise<IMissionWithDateAsMoment[]> {
    return async (dispatch: (x: IReduxAction) => void): Promise<IMissionWithDateAsMoment[]> => {
        dispatch({
            type: MissionTypes.SET_IS_LOADING_FETCH_MISSIONS,
            payload: true
        });
        let missions: IMission[] = [];
        let fetchedMissions: any[] | null = null;
        let skip = 0;
        const step = 5000;
        while (fetchedMissions === null || (Array.isArray(fetchedMissions) && fetchedMissions.length > 0)) {
            fetchedMissions = await fetchMissions({
                ...params,
                limit: step,
                skip
            });
            missions = missions.concat(fetchedMissions);
            skip += step;
        }
        const missionsWithMomentFromMissions = getMissionsWithDateAsMomentFromMissions(
            missions,
            institutionHasWorkersDict
        );
        dispatch({
            type: MissionTypes.FETCH_MISSIONS,
            payload: missionsWithMomentFromMissions
        });
        dispatch({
            type: MissionTypes.SET_IS_LOADING_FETCH_MISSIONS,
            payload: false
        });
        return missionsWithMomentFromMissions;
    };
}

export function setIsLoadingFetchMissionsAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: MissionTypes.SET_IS_LOADING_FETCH_MISSIONS,
            payload: bool
        });
    };
}

export function setFilteredMissions(
    filteredMissions: IMissionWithDateAsMoment[][]
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: MissionTypes.SET_FILTERED_MISSIONS,
            payload: filteredMissions
        });
    };
}

export function setIsLoadingFilteredMissionsAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: MissionTypes.SET_IS_LOADING_FILTERED_MISSIONS,
            payload: bool
        });
    };
}

export function setMissionEvolutionModeAction(mode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: MissionTypes.SET_MISSION_EVOLUTION_MODE,
            payload: mode
        });
    };
}

export function setMissionFilledEvolutionModeAction(mode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: MissionTypes.SET_MISSION_FILLED_RATE_EVOLUTION_MODE,
            payload: mode
        });
    };
}

export function setCancelledMissionEvolutionModeAction(mode: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: MissionTypes.SET_CANCELLED_MISSION_EVOLUTION_MODE,
            payload: mode
        });
    };
}
