import { QueryObserverResult, useQuery } from "react-query";

import AxiosInstance from "../../module/axios/axios.instance";
import { FeatureFlagsResponse } from "../../types/launchdarkly/featureFlags";

import { getFlagsUrl } from "./config";

export const useGetFeatureTogglesQuery = (): QueryObserverResult<FeatureFlagsResponse> => {
    return useQuery(
        ["get-feature-toggles"],
        async () => {
            const result = await AxiosInstance.post<FeatureFlagsResponse>(getFlagsUrl, {
                userAgent: global.navigator.userAgent
            });
            return result.data;
        },
        {
            cacheTime: 30000
        }
    );
};
