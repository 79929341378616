require("dotenv").config({ silent: true });

import Axios from "../axios/axios.instance";

import { isMobile } from "../toolbox/device";

export const adminLogout = async (shouldCallBackend = true, session = false): Promise<void> => {
    try {
        const serviceAppUrl = process.env.REACT_APP_SERVICE_APP_URL;
        const bffAdminUrl = process.env.REACT_APP_BFF_ADMIN_URL;

        if (shouldCallBackend) {
            if (session) {
                await Axios.post(`${bffAdminUrl}/logout`);
            } else {
                await Axios.post(`${serviceAppUrl}/admin/logout`);
            }
        }

        localStorage.clear();

        if (isMobile()) {
            window.location.href = `${serviceAppUrl}/mobile#/adminLogin`;
        } else {
            window.location.href = `${serviceAppUrl}/loginadmin`;
        }
    } catch (err) {
        console.error(`Error while calling serviceapp adminLogout: ${err}`);
    }
};
