import { IMissionLang } from "../interface/Mission.interface";

export const frMission: IMissionLang = {
    missionNumberEvolution: "évolution du nombre de missions",
    allMissions: "Toutes les missions",
    urgent: "Urgentes",
    createdMissions: "Missions créées",
    urgentMissionTooltip:
        "<strong>Une mission urgente</strong> est une mission dont la <strong>durée entre la création de la mission</strong> (ou la validation du besoin) <strong>et le début de la mission est inférieure ou égale à 48h</strong>.",
    missionFilledRate: "Taux de missions pourvues",
    posted: "Postées",
    declared: "Déclarées",
    planned: "Planifiées",
    missionTypeRepartition: "Répartition des missions par type",
    missionCreatedNumberByService: "Nombre de missions créées par service",
    missionCanceledRate: "Taux d'annulation des missions",
    cancelRate: "Taux d'annulation",
    filledNotFilledRepartition: "Répartition pourvues / non pourvues",
    rateOfCanceledMissions: "Taux de missions annulées",
    cancellationMotifsInstitution: {
        activityReduction: "Réduction d'activité",
        workerFindOutOfNetwork: "Remplaçant trouvé en dehors de Hublo",
        institutionInterim: "Intérim",
        institutionNoWorkerNeeded: "Situation gérée sans remplaçant",
        institutionTypingError: "Erreur de saisie",
        nbHourForWorkerOverCome: "Nombre d'heures autorisé dépassé pour ce remplaçant",
        findOutOfHublo: "Remplaçant heures supp trouvé en dehors de Hublo",
        internalOrganisation: "Réorganisation interne",
        institutionOtherReason: "Autre motif"
    },
    cancellationMotifsWorker: {
        cancelByWorker: "Annulation par le remplaçant",
        workerDidntCome: "Le remplaçant ne s'est pas présenté"
    },
    cancellationMotifForInstitution: "Motif d'annulation - Établissement",
    cancellationMotifForWorker: "Motif d'annulation - Remplaçant",
    cancelledFilled: "Annulées - pourvues",
    cancelledNotFilled: "Annulées - non pourvues",
    outsidePole: "Hors pôle",
    unspecifiedService: "Service non spécifié"
};
