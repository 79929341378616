import { IFooterLang } from "../interface/Footer.interface";

export const enFooter: IFooterLang = {
    customerPartners: "Customers/Partners",
    solution: "Solution",
    about: "About",
    contact: "Contact",
    blog: "Blog",
    whitePapers: "White papers",
    faq: "FAQ",
    pages: "pages",
    weAreConnected: "we are connected",
    allRightsReserved: "All rights reserved.",
    TermsOfService: "General conditions of use",
    languages: "languages",
    language: "Language",
    noResult: "No results",
    mstaffSolution: "MSTAFF"
};
