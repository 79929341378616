import moment from "moment";
import { IPeriodInfo } from "../../../Models/PeriodInfo";
import { ILang } from "../../lang/interface";

export function getPeriodFromPeriodInfo(periodInfo: IPeriodInfo): number {
    if (periodInfo.unit === "year") {
        return 12 + (periodInfo.mode === "rolling" ? 1 : 0);
    }
    return periodInfo.period + (periodInfo.mode === "rolling" ? 1 : 0);
}

export function convertPeriodUnit(unit: moment.unitOfTime.Base): moment.unitOfTime.StartOf {
    return unit === "week" ? "isoWeek" : unit;
}

export function getTimeStepsFromPeriodInfo(periodInfo: IPeriodInfo, format: string = "MM/DD/YYYY"): string[] {
    return Array(getPeriodFromPeriodInfo(periodInfo))
        .fill(-1)
        .map((e, i): number => i)
        .map((e: number, i: number): string => {
            if (periodInfo.unit === "year") {
                return moment()
                    .subtract(periodInfo.period, "year")
                    .startOf("year")
                    .add(i, "month")
                    .format(format);
            }
            return moment()
                .subtract(periodInfo.period - i, periodInfo.unit)
                .startOf(convertPeriodUnit(periodInfo.unit))
                .format(format);
        });
}

export function getTimeLabelsPeriodInfo(periodInfo: IPeriodInfo, currentLang: string, lang: ILang): string[] {
    const timeSteps = getTimeStepsFromPeriodInfo(periodInfo);
    let labels = [];
    for (let i = 0; i < timeSteps.length; i++) {
        labels.push(
            moment(timeSteps[i])
                .locale(currentLang)
                .format(periodInfo.unit !== "week" ? "MMM YYYY" : "DD MMM YYYY")
        );
    }
    if (labels.length !== 0 && periodInfo.mode === "rolling") {
        labels[labels.length - 1] = labels[labels.length - 1].concat(` (${lang.onGoing})`);
    }
    return labels;
}

export function getTimeRangesFromPeriodInfo(periodInfo: IPeriodInfo): string[][] {
    const timeSteps = getTimeStepsFromPeriodInfo(periodInfo);
    const timeRanges: string[][] = [];
    for (let index = 0; index < timeSteps.length - 1; index++) {
        timeRanges.push([timeSteps[index], timeSteps[index + 1]]);
    }
    if (periodInfo.mode === "rolling") {
        timeRanges.push([
            timeSteps[timeSteps.length - 1],
            moment()
                .endOf(periodInfo.unit === "week" ? "isoWeek" : "month")
                .subtract(1, "minute")
                .format("MM/DD/YYYY HH:mm")
        ]);
    } else if (periodInfo.mode === "strict") {
        timeRanges.push([
            timeSteps[timeSteps.length - 1],
            moment(timeSteps[timeSteps.length - 1])
                .endOf(convertPeriodUnit(periodInfo.unit))
                .endOf("day")
                .subtract(1, "minute")
                .format("MM/DD/YYYY HH:mm")
        ]);
    }
    return timeRanges;
}
