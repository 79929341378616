enum NetworkTypes {
    GET_ACTIVE_NETWORK_EVOLUTION = "GET_ACTIVE_NETWORK_EVOLUTION",
    FETCH_SALARY_WORKERS_COUNTS = "FETCH_SALARY_WORKERS_COUNTS",
    FETCH_TEMPORARY_WORKERS_COUNTS = "FETCH_TEMPORARY_WORKERS_COUNTS",
    SET_NETWORK_EVOLUTION_MODE = "SET_NETWORK_EVOLUTION_MODE",
    SET_IS_LOADING_ACTIVE_NETWORK_EVOLUTION = "SET_IS_LOADING_ACTIVE_NETWORK_EVOLUTION",
    SET_IS_LOADING_FETCH_SALARY_WORKERS_COUNT = "SET_IS_LOADING_FETCH_SALARY_WORKERS_COUNT",
    SET_IS_LOADING_FETCH_TEMPORARY_WORKERS_COUNT = "SET_IS_LOADING_FETCH_TEMPORARY_WORKERS_COUNT"
}

export default NetworkTypes;
