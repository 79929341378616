import moment, { Moment } from "moment";
import { IMissionWithDateAsMoment, IMission } from "../../../Models/Mission";
import memoizeOne from "memoize-one";
import { getTimeRangesFromPeriodInfo } from "./time";
import { isEqual } from "lodash";
import { ISelectLabelValue } from "@hublo/legacy-components";
import { getPeriodInfoFromPeriodFilterValue } from "../constant/PeriodInfo.constants";
import { setFilteredMissions, setIsLoadingFilteredMissionsAction } from "../../store/action/mission.actions";
import store from "../../store";
import { IMissionMotifCreation } from "../../../Models/MissionMotifCreation";

export function hasMissionGoodBeginAtRange(
    mission: IMissionWithDateAsMoment,
    rangeBeginAt: { startDate: Moment; endDate: Moment }
): boolean {
    return (
        mission.beginAt.isSameOrAfter(rangeBeginAt.startDate) && mission.beginAt.isSameOrBefore(rangeBeginAt.endDate)
    );
}

export function hasMissionGoodInstitution(
    mission: IMissionWithDateAsMoment | IMission,
    institutions: number[]
): boolean {
    return institutions.filter((institution): boolean => mission.institution === institution).length > 0;
}

export function hasMissionAtLeastOneGoodJob(mission: IMissionWithDateAsMoment | IMission, jobs: number[]): boolean {
    return jobs.length === 0 || jobs.filter((job): boolean => mission.job.indexOf(job) !== -1).length > 0;
}

export function hasMissionGoodService(mission: IMissionWithDateAsMoment | IMission, services: number[]): boolean {
    return services.length === 0 || services.filter((service): boolean => mission.service === service).length > 0;
}

export function isMissionUrgent(mission: IMissionWithDateAsMoment | IMission): boolean {
    return Math.abs(mission.beginAt.diff(mission.createdAt, "days", true)) <= 2;
}

export function isMissionFilled(mission: IMissionWithDateAsMoment | IMission): boolean {
    return mission.isFilled === true;
}

export function isMissionPosted(mission: IMissionWithDateAsMoment | IMission): boolean {
    return mission.isPastMission === false && mission.isFutureMission === false && mission.isPlanning === false;
}

export function isMissionDeclared(mission: IMissionWithDateAsMoment | IMission): boolean {
    return mission.isPastMission === true || mission.isFutureMission === true;
}

export function isMissionPlanned(mission: IMissionWithDateAsMoment | IMission): boolean {
    return mission.isPlanning === true;
}

export function isMissionCanceled(mission: IMissionWithDateAsMoment | IMission): boolean {
    return mission.isCanceled === true;
}

export function hasMissionGoodCanceledMotif(
    mission: IMissionWithDateAsMoment | IMission,
    idCancelMotif: number
): boolean {
    return mission.canceledBecause === idCancelMotif;
}

export function hasMissionGoodWorkerStatus(mission: IMissionWithDateAsMoment, workerStatus: number): boolean {
    return mission.workerStatus === workerStatus;
}

export function hasMissionGoodMissionMotifCreationCategory(
    mission: IMissionWithDateAsMoment,
    missionMotifCreations: IMissionMotifCreation[],
    motifCategory: number
): boolean {
    const missionMotifCreation = missionMotifCreations.find((e): boolean => e.id === mission.motif);
    const missionMotifCategory = missionMotifCreation ? missionMotifCreation.missionmotifcreationcategory : -1;
    return missionMotifCategory === motifCategory;
}

export const getMissionEvolutions = memoizeOne(
    async (
        missions: IMissionWithDateAsMoment[],
        selectedPeriod: ISelectLabelValue,
        selectedInstitutions: number[],
        selectedJobs: number[],
        selectedServices: number[]
    ): Promise<IMissionWithDateAsMoment[][]> => {
        const periodInfo = getPeriodInfoFromPeriodFilterValue(selectedPeriod);
        const timeRanges = getTimeRangesFromPeriodInfo(periodInfo);
        const convertedTimeRanges = timeRanges.map((e): Moment[] => e.map((e2): Moment => moment(e2)));
        await setIsLoadingFilteredMissionsAction(true)(store.dispatch);
        let filteredMissions: IMissionWithDateAsMoment[][] = Array(convertedTimeRanges.length).fill(0);
        for (let i = 0; i < missions.length; i++) {
            for (let j = 0; j < filteredMissions.length; j++) {
                if (
                    hasMissionGoodBeginAtRange(missions[i], {
                        startDate: convertedTimeRanges[j][0],
                        endDate: convertedTimeRanges[j][1]
                    }) &&
                    hasMissionGoodInstitution(missions[i], selectedInstitutions) &&
                    hasMissionAtLeastOneGoodJob(missions[i], selectedJobs) &&
                    hasMissionGoodService(missions[i], selectedServices)
                ) {
                    if (!filteredMissions[j]) {
                        filteredMissions[j] = [missions[i]];
                    } else {
                        filteredMissions[j].push(missions[i]);
                    }
                    break;
                }
            }
        }
        filteredMissions = filteredMissions.map((e): IMissionWithDateAsMoment[] => (!e ? [] : e));
        await setIsLoadingFilteredMissionsAction(false)(store.dispatch);
        setFilteredMissions(filteredMissions)(store.dispatch);
        return filteredMissions;
    },
    isEqual
);
