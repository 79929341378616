import { frCommon } from "./common";
import { frWorker } from "./worker";
import { frNavbar } from "./navbar";
import { frDashboard } from "./dashboard";
import { frFilter } from "./filter";
import { frNetwork } from "./network";
import { frHRMonitoring } from "./hrMonitoring";
import { frMission } from "./mission";
import { frFooter } from "./footer";
export default Object.assign(
    {},
    frCommon,
    frWorker,
    frNavbar,
    frDashboard,
    frFilter,
    frNetwork,
    frHRMonitoring,
    frMission,
    frFooter
);
