import { IInstitutionOption } from "../../../Models/InstitutionOption";
import { PossibleFetchs, PossibleApis } from "../../module/constant/Api.constants";
import { fetchByIdSubFieldFactory } from "../../module/factory/fetchById.factory";

export async function fetchInstitutionOptions(idInstitution: number): Promise<IInstitutionOption> {
    return fetchByIdSubFieldFactory<IInstitutionOption>(PossibleFetchs.institutionOptions, PossibleApis.serviceApi)(
        idInstitution,
        {
            select: []
        }
    );
}

export async function fetchInstitutionsOptions(
    idInstitutions: number[]
): Promise<{ [key: number]: IInstitutionOption }> {
    return (
        await Promise.all(idInstitutions.map((id: number): Promise<IInstitutionOption> => fetchInstitutionOptions(id)))
    ).reduce((p: any, c, i): { [key: number]: IInstitutionOption } => {
        p[idInstitutions[i]] = c;
        return p;
    }, {});
}
