import { ICommonLang } from "../interface/Common.interface";

export const esCommon: ICommonLang = {
    anErrorOccured: "Se ha producido un error ",
    download: "descargar ",
    new: "nuevo",
    next: "siguiente",
    notSpecifiedFemale: "sin rellenar",
    notSpecifiedMale: "sin rellenar",
    previous: "anterior",
    resetFilters: "restablecer los filtros",
    search: "buscar",
    maintenanceBanner:
        "Hublo está experimentando problemas de rendimiento y estamos haciendo todo lo posible para solucionarlo. Sentimos las molestias."
};
