import { IReduxAction } from "../store.types";
import InstitutionOptionTypes from "../type/institutionOption.types";

const initialState: any = {
    institutionOptions: {},
    institutionsOptions: {},
    isLoadingFetchInstitutionOptions: false,
    isLoadingFetchInstitutionsOptions: false
};
export default function institutionOptionsReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InstitutionOptionTypes.FETCH_INSTITUTION_OPTIONS:
            return {
                ...state,
                institutionOptions: action.payload
            };
        case InstitutionOptionTypes.FETCH_INSTITUTIONS_OPTIONS:
            return {
                ...state,
                institutionsOptions: action.payload
            };
        case InstitutionOptionTypes.SET_IS_LOADING_FETCH_INSTITUTION_OPTIONS:
            return {
                ...state,
                isLoadingFetchInstitutionOptions: action.payload
            };
        case InstitutionOptionTypes.SET_IS_LOADING_FETCH_INSTITUTIONS_OPTIONS:
            return {
                ...state,
                isLoadingFetchInstitutionsOptions: action.payload
            };
        default:
            return state;
    }
}
