import { IQueryParams } from "medgo-query-generator";
import { fetchFactory } from "../../module/factory/fetch.factory";
import { PossibleFetchs, PossibleApis, FetchLimits } from "../../module/constant/Api.constants";
import { IInstitutionHasWorker } from "../../../Models/InstitutionHasWorker";

export async function fetchInstitutionHasWorkers(params: IQueryParams): Promise<IInstitutionHasWorker[]> {
    return fetchFactory<IInstitutionHasWorker>(PossibleFetchs.institutionHasWorkers, PossibleApis.serviceApi)(
        {
            select: ["id", "createdAt", "deletedAt", "statut", "institution", "worker", "job", "isDeletedFromNetwork"],
            limit: params.limit ? params.limit : FetchLimits.institutionHasWorkers
        },
        params
    );
}
