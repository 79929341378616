import { IService } from "../../../Models/Service";
import store from "../../store";

export function getEmptyServiceInstance(): IService {
    // @ts-ignore
    const lang = store.getState().language.lang;
    // @ts-ignore
    const institutions = store.getState().institutions.institutions;
    return {
        id: -1,
        name: lang.unspecifiedService,
        institution: institutions,
        pole: null,
        isOnForCreatingMission: true
    };
}

export function resetEmptyServiceInstance(): void {
    let restrictedServices: any = store.getState().services;
    const count = restrictedServices.services.length;
    // @ts-ignore
    restrictedServices = restrictedServices.services.filter((service): boolean => {
        return service.id !== -1;
    });
    if (count !== restrictedServices.length) {
        restrictedServices.push(getEmptyServiceInstance());
    }
    return restrictedServices;
}
