import { IFooterLang } from "../interface/Footer.interface";

export const esFooter: IFooterLang = {
    about: "Sobre nosotros ",
    allRightsReserved: "Todos los derechos reservados ",
    blog: "Blog ",
    contact: "Contacta con nosotros",
    customerPartners: "Clientes/Socios ",
    faq: "FAQ",
    language: "Idioma",
    languages: "Idiomas ",
    noResult: "Ningún resultado",
    pages: "Páginas ",
    solution: "Solución ",
    TermsOfService: "Condiciones generales de uso",
    weAreConnected: "estamos conectados ",
    whitePapers: "Recursos",
    mstaffSolution: "MSTAFF"
};
