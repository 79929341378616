import React from "react";
import { HashRouter, Route } from "react-router-dom";
// @ts-ignore
import loadable from "@loadable/component";
import { ILabelHref, NavbarComponent } from "@hublo/legacy-components";
import { connect } from "react-redux";
import { fetchMeAction } from "./store/action/auth.actions";
import { fetchAdminAction } from "./store/action/admin.actions";
import { fetchInstitutionsAction } from "./store/action/institution.actions";
import { fetchInstitutionsOptionsAction } from "./store/action/institutionOption.actions";
import {
    fetchInstitutionsGroupsGroupingAction,
    fetchInstitutionsGroupsClusterAction
} from "./store/action/institutionGroup.actions";
import { fetchLevelAction } from "./store/action/level.actions";
import { fetchJobsAction } from "./store/action/job.actions";
import { fetchSpecialtiesAction } from "./store/action/specialty.actions";
import { fetchPolesAction } from "./store/action/pole.actions";
import { fetchServicesAction } from "./store/action/service.actions";
import { fetchMissionMotifCreationsAction } from "./store/action/missionMotifCreation.actions";
import {
    fetchInstitutionHasWorkersAction,
    setIsLoadingFetchInstitutionHasWorkersAction
} from "./store/action/institutionHasWorker.actions";
import { fetchMissionsAction, setIsLoadingFetchMissionsAction } from "./store/action/mission.actions";
import { setLangAction } from "./store/action/lang.actions";
import { setIsLoadingAppAction } from "./store/action/app.actions";
import { firstLetterUppercase } from "./module/toolbox/case";
import { IAdmin } from "../Models/Admin";
import { isNullOrUndefined } from "util";
import { IInstitution } from "../Models/Institution";
import { IQueryParams } from "medgo-query-generator";
import { CLUSTER_TYPE, GROUPING_TYPE } from "./module/constant/InstitutionGroup.constants";
import { ILevel } from "../Models/Level";
import { IService } from "../Models/Service";
import LoadingContainer from "./component/element/Loading.container";
import { IInstitutionOption } from "../Models/InstitutionOption";
import { STATUTS } from "./module/constant/InstitutionHasWorker.constants";
import moment from "moment";
import { IMissionWithDateAsMoment } from "../Models/Mission";
import { IReducedInstitutionHasWorker } from "../Models/InstitutionHasWorker";
import { isMobile } from "./module/toolbox/device";
import { LangConstants, DefaultLanguage, EAvailableLang } from "./module/constant/International.constant";
import { ILang } from "./lang/interface";
import { decodeToken } from "./module/toolbox/token";
import { adminLogout } from "./module/logout";
import { handleSSOAdminIfNeeded } from "./module/authorization/sso";
import { Url } from "npm-hublo-toolbox";
import {
    FeatureTogglesFlagsContext,
    withFeatureTogglesConsumer
} from "./context/feature-toggles/FeatureToggles.context";
import { setupAxeptio } from "npm-hublo-analytics";
import { SlowDownBanner } from "./component/element/SlowDownBanner";
import { isAllowedTo } from "./service/auth/permission.service";
import { Permission } from "./types/auth/permission.types";
import setupZendeskWidget from "./module/zendesk";

require("moment/min/locales.min");

const DashboardBaseContainer = loadable((): Promise<{}> => import("./container/DashboardBase.container"), {});

interface IReduxPropsApp {
    me: IAdmin;
    admin: IAdmin;
    level: ILevel;
    lang: ILang;
    currentLang: string;
    institutions: IInstitution[];
    institutionsOptions: IInstitutionOption[];
    services: IService[];
    isLoadingApp: boolean;
}

interface IReduxActionsApp {
    fetchMeAction: () => Promise<void>;
    fetchAdminAction: (idAdmin: number) => Promise<void>;
    fetchLevelAction: (idLevel: number) => Promise<void>;
    fetchInstitutionsAction: () => Promise<void>;
    fetchInstitutionHasWorkersAction: (
        params?: IQueryParams
    ) => Promise<{ [key: number]: IReducedInstitutionHasWorker }>;
    fetchInstitutionsOptionsAction: (idInstitutions: number[]) => Promise<void>;
    fetchInstitutionsGroupsGroupingAction: (idInstitutions: number[], params?: IQueryParams) => Promise<void>;
    fetchInstitutionsGroupsClusterAction: (idInstitutions: number[], params?: IQueryParams) => Promise<void>;
    fetchJobsAction: (idInstitutions: number[]) => Promise<void>;
    fetchSpecialtiesAction: (idInstitutions: number[]) => Promise<void>;
    fetchPolesAction: (idPoles: number[]) => Promise<void>;
    fetchServicesAction: (idAdmin: number, isAllowedToSeeMissionOutsideService: boolean) => Promise<void>;
    fetchMissionsAction: (
        query: IQueryParams,
        institutionHasWorkersDict: { [key: number]: IReducedInstitutionHasWorker }
    ) => Promise<IMissionWithDateAsMoment[]>;
    fetchMissionMotifCreationsAction: (idInstitutions: number[]) => Promise<void>;
    setLangAction: (lang: EAvailableLang) => void;
    setIsLoadingAppAction: (isLoadingApp: boolean) => void;
    setIsLoadingFetchMissionsAction: (bool: boolean) => void;
    setIsLoadingFetchInstitutionHasWorkersAction: (bool: boolean) => void;
}
interface IPropsApp extends IReduxPropsApp, IReduxActionsApp {
    ldClient?: FeatureTogglesFlagsContext;
}

interface IStateProps {
    alreadyIdentify: boolean;
    indentifyInProgress: boolean;
    closedSlowDownBanner: boolean;
}

class App extends React.Component<IPropsApp, IStateProps> {
    constructor(props: IPropsApp) {
        super(props);
        this.logout = this.logout.bind(this);
        Url.checkAndSaveTokensFromUrl();
        Url.removeTokensFromUrl(window.location.href);
        this.props.setIsLoadingAppAction(true);
        this.props.setIsLoadingFetchInstitutionHasWorkersAction(true);
        this.props.setIsLoadingFetchMissionsAction(true);
        new Promise<void>(
            async (resolve): Promise<void> => {
                try {
                    await this.props.fetchMeAction();
                    await this.props.fetchAdminAction(this.props.me.id);
                    const langConstant = LangConstants.find(
                        (e): boolean => e.idLanguage === this.props.admin.idLanguage
                    );
                    this.props.setLangAction(
                        !isNullOrUndefined(langConstant) ? langConstant.lang : DefaultLanguage.lang
                    );
                    await this.props.fetchLevelAction(this.props.me.level);

                    const isAllowedToSeeMissionOutsideService = isAllowedTo({
                        institutions: this.props.me.institutionIds,
                        permission: Permission.SEE_MISSION_OUTSIDE_SERVICE,
                        permissions: this.props.me.permissions
                    });

                    if (
                        !isAllowedTo({
                            institutions: this.props.me.institutionIds,
                            permission: Permission.SEE_STATISTICS_PAGE,
                            permissions: this.props.me.permissions
                        }) &&
                        !this.props.me.isStatisticAllowed
                    ) {
                        window.location.href = process.env.REACT_APP_SERVICE_APP_URL
                            ? process.env.REACT_APP_SERVICE_APP_URL.concat("/pro/vacataires")
                            : "/";
                    }

                    await this.props.fetchInstitutionsAction();
                    await this.props.fetchInstitutionsOptionsAction(this.props.institutions.map((e): number => e.id));
                    await this.props.fetchServicesAction(this.props.me.id, isAllowedToSeeMissionOutsideService);
                    this.props.fetchPolesAction(
                        this.props.services
                            .filter((e): boolean => !isNullOrUndefined(e.pole))
                            .map((e): number => {
                                // @ts-ignore
                                return e.pole;
                            })
                            .sort()
                            .filter((e, i, arr): boolean => i === 0 || (i > 0 && arr[i - 1] !== e))
                    );
                    this.props
                        .fetchInstitutionHasWorkersAction({
                            query: {
                                institution: this.props.institutions.map((e): number => e.id),
                                statut: STATUTS
                            }
                        })
                        .then(
                            (institutionHasWorkersDict): Promise<IMissionWithDateAsMoment[]> =>
                                this.props.fetchMissionsAction(
                                    {
                                        query: {
                                            institution: this.props.institutions.map((e): number => e.id),
                                            rangeBeginAt: {
                                                startDate: moment()
                                                    .subtract(1, "year")
                                                    .startOf("year"),
                                                endDate: moment().endOf("year")
                                            }
                                        }
                                    },
                                    institutionHasWorkersDict
                                )
                        );
                    await Promise.all([
                        this.props.fetchInstitutionsGroupsClusterAction(
                            this.props.institutions.map((e): number => e.id),
                            {
                                query: {
                                    type: [CLUSTER_TYPE]
                                }
                            }
                        ),
                        this.props.fetchInstitutionsGroupsGroupingAction(
                            this.props.institutions.map((e): number => e.id),
                            {
                                query: {
                                    type: [GROUPING_TYPE]
                                }
                            }
                        ),
                        this.props.fetchJobsAction(this.props.institutions.map((e): number => e.id)),
                        this.props.fetchSpecialtiesAction(this.props.institutions.map((e): number => e.id)),
                        ,
                        this.props.fetchMissionMotifCreationsAction(this.props.institutions.map((e): number => e.id))
                    ]);
                    resolve();
                } catch (err) {
                    window.location.href = process.env.REACT_APP_SERVICE_APP_URL + "/loginadmin";
                }
            }
        ).then((): void => {
            this.props.setIsLoadingAppAction(false);
        });

        this.state = { alreadyIdentify: false, indentifyInProgress: false, closedSlowDownBanner: false };
    }
    async logout(): Promise<void> {
        const token = localStorage.getItem("token");
        if (token && decodeToken(token)) {
            return adminLogout();
        }

        return adminLogout(true, true);
    }

    async componentDidMount(): Promise<void> {
        // handle SSO admins
        await handleSSOAdminIfNeeded();

        // setup axeptio
        setupAxeptio();

        // setup Zendesk
        if (this.props.currentLang === EAvailableLang.fr) {
            setupZendeskWidget();
        }
    }

    componentDidUpdate(prevProps: IPropsApp): void {
        // re-setup axeptio and zendesk on language change
        if (prevProps.currentLang !== this.props.currentLang) {
            // setup axeptio
            setupAxeptio();

            // setup Zendesk
            if (this.props.currentLang === EAvailableLang.fr) {
                setupZendeskWidget();
            }
        }
    }

    async UNSAFE_componentWillReceiveProps(): Promise<void> {
        if (
            !this.state.indentifyInProgress &&
            !this.state.alreadyIdentify &&
            this.props.ldClient &&
            this.props.me.id &&
            this.props.me.email &&
            this.props.institutions.length
        ) {
            this.setState({ indentifyInProgress: true });
            this.setState({ alreadyIdentify: true, indentifyInProgress: false });
        }
    }

    generateTokensString(): string {
        const token = localStorage.getItem("token");
        const ssoAccessToken = localStorage.getItem("SSOAccessToken");
        let res = token && token !== "" ? `?token=${token}` : null;
        if (ssoAccessToken && ssoAccessToken !== "") {
            res = `${res ? `${res}&` : "?"}SSOAccessToken=${ssoAccessToken}`;
        }
        return res ?? "";
    }

    render(): JSX.Element {
        const displayName = this.props.me
            ? `${this.props.me.firstName ? this.props.me.firstName : ""} ${
                  this.props.me.lastName ? this.props.me.lastName : ""
              }`
            : "";
        const SSOAccessToken = localStorage.getItem("SSOAccessToken");
        const ssoAccesTokenUrlParam = SSOAccessToken ? `&SSOAccessToken=${SSOAccessToken}` : "";
        const JWTToken = localStorage.getItem("token");
        const tokenUrlParam = JWTToken ? `?token=${JWTToken}${SSOAccessToken ? "&" : ""}` : "";
        const tokensString = this.generateTokensString();
        const dropdownItems: ILabelHref[] = [
            {
                label: firstLetterUppercase(this.props.lang.profile),
                href: process.env.REACT_APP_SERVICE_BACKOFFICE_URL
                    ? `${process.env.REACT_APP_SERVICE_BACKOFFICE_URL}/me/profile${tokenUrlParam}${ssoAccesTokenUrlParam}`
                    : "/"
            }
        ];

        if (
            isAllowedTo({
                institutions: this.props.me.institutionIds,
                permission: Permission.SEE_PRO_INSTITUTION_PAGE,
                permissions: this.props.me.permissions
            })
        ) {
            dropdownItems.push({
                label: firstLetterUppercase(this.props.lang.myInstitutions),
                href: process.env.REACT_APP_SERVICE_BACKOFFICE_URL
                    ? `${process.env.REACT_APP_SERVICE_BACKOFFICE_URL}/me/institutions${tokenUrlParam}${ssoAccesTokenUrlParam}`
                    : "/"
            });
        }

        dropdownItems.push({
            label: firstLetterUppercase(this.props.lang.myNotifications),
            href: process.env.REACT_APP_SERVICE_BACKOFFICE_URL
                ? `${process.env.REACT_APP_SERVICE_BACKOFFICE_URL}/me/notifications${tokenUrlParam}${ssoAccesTokenUrlParam}`
                : "/"
        });

        if (
            isAllowedTo({
                institutions: this.props.me.institutionIds,
                permission: Permission.SEE_PRO_INSTITUTION_CONFIG_PAGE,
                permissions: this.props.me.permissions
            })
        ) {
            dropdownItems.push({
                label: firstLetterUppercase(`${this.props.lang.myConfig}`),
                href: process.env.REACT_APP_SERVICE_BACKOFFICE_URL
                    ? process.env.REACT_APP_SERVICE_BACKOFFICE_URL.concat(tokensString)
                    : "/"
            });
            dropdownItems.push({
                label: "Go to URL",
                onClick: (): void => {
                    const url = prompt("Write your link below :", "https://app.hubpreprod.com/");
                    if (url) {
                        window.location.href = url;
                    }
                }
            });
        }

        dropdownItems.push({
            label: firstLetterUppercase(this.props.lang.logout),
            onClick: this.logout
        });

        let menuItems: any[] = [
            {
                label: this.props.lang.proMissions,
                href: process.env.REACT_APP_SERVICE_APP_URL
                    ? process.env.REACT_APP_SERVICE_APP_URL.concat("/pro/missions2")
                    : "/"
            },
            {
                label: this.props.lang.proVacataires,
                href: process.env.REACT_APP_SERVICE_APP_URL
                    ? process.env.REACT_APP_SERVICE_APP_URL.concat("/pro/vacataires2")
                    : "/"
            }
        ];
        if (
            !this.props.isLoadingApp &&
            Object.keys(this.props.institutionsOptions).length > 0 &&
            Object.keys(this.props.institutionsOptions).reduce(
                (acc, current: any): boolean => acc || !!this.props.institutionsOptions[current].hasContract,
                false
            ) &&
            (this.props.me.contractLevel === 9 || this.props.me.level === 1)
        ) {
            menuItems.push({
                label: this.props.lang.proContracts,
                href: process.env.REACT_APP_SERVICE_CONTRACT_URL
                    ? process.env.REACT_APP_SERVICE_CONTRACT_URL.concat(tokensString)
                    : "/"
            });
        }
        const idInstitutions = this.props.institutions.map((institution: IInstitution): number => {
            if (institution.id) return institution.id;
            else return 0;
        });
        if (
            !this.props.isLoadingApp &&
            idInstitutions.length === Object.keys(this.props.institutionsOptions).length &&
            Object.keys(this.props.institutionsOptions).length !== 0
        ) {
            const institutionsHasContract = idInstitutions.map((idInstitution: number): boolean => {
                if (this.props.institutionsOptions[idInstitution].hasContract) {
                    return true;
                } else {
                    return false;
                }
            });
            const isDemoContractAllowed =
                institutionsHasContract.indexOf(true) === -1 && this.props.currentLang === "fr" ? true : false;
            if (isDemoContractAllowed) {
                menuItems.push({
                    label: this.props.lang.contractDemo,
                    onClick: (): void => {
                        window.open(process.env.SERVICE_CONTRACT_DEMO_URL ?? "https://hublo.com/fr/contract");
                    }
                });
            }
        }
        menuItems = menuItems.concat([
            {
                label: this.props.lang.dashboard,
                href: "/",
                isActive: true
            }
        ]);

        if (this.props.ldClient?.flags?.useTalentApp) {
            menuItems.push({
                label: this.props.lang.talent,
                href: process.env.REACT_APP_TALENT_APP_URL || "/"
            });
        }

        if (this.props.ldClient?.flags?.usePlatformSlowdownBanner === false) {
            localStorage.removeItem("showSlownessBanner");
        }

        const showSlowDownBanner =
            localStorage.getItem("showSlownessBanner") !== "false" &&
            this.state.closedSlowDownBanner === false &&
            this.props.ldClient?.flags?.usePlatformSlowdownBanner;

        return (
            <div className="App">
                {showSlowDownBanner && (
                    <SlowDownBanner
                        onClickClose={(): void => {
                            this.setState({ closedSlowDownBanner: true });
                            localStorage.setItem("showSlownessBanner", "false");
                        }}
                        text={this.props.lang.maintenanceBanner}
                    />
                )}
                {!this.props.isLoadingApp ? (
                    <NavbarComponent
                        logout={this.logout}
                        displayName={displayName}
                        dropdownItems={dropdownItems}
                        menuItems={menuItems}
                    />
                ) : null}
                {!isMobile() ? (
                    <LoadingContainer isLoading={this.props.isLoadingApp} isFullPage={true}>
                        <HashRouter>
                            <Route path="/*" component={DashboardBaseContainer} />
                        </HashRouter>
                    </LoadingContainer>
                ) : (
                    <div className="is-open-sans is-bold has-medium-font-size is-vcentered text-center">
                        {this.props.lang.mobilePage}
                    </div>
                )}
            </div>
        );
    }
}

export default connect(
    (centralState: any): IReduxPropsApp => ({
        me: centralState.auth.user,
        admin: centralState.admin.admin,
        level: centralState.level.level,
        lang: centralState.language.lang,
        currentLang: centralState.language.currentLang,
        institutions: centralState.institutions.institutions,
        institutionsOptions: centralState.institutionsOptions.institutionsOptions,
        services: centralState.services.services,
        isLoadingApp: centralState.app.isLoadingApp
    }),
    {
        fetchMeAction,
        fetchAdminAction,
        fetchLevelAction,
        fetchInstitutionsAction,
        fetchInstitutionsOptionsAction,
        fetchInstitutionsGroupsGroupingAction,
        fetchInstitutionsGroupsClusterAction,
        fetchInstitutionHasWorkersAction,
        fetchJobsAction,
        fetchSpecialtiesAction,
        fetchPolesAction,
        fetchServicesAction,
        fetchMissionsAction,
        fetchMissionMotifCreationsAction,
        setLangAction,
        setIsLoadingAppAction,
        setIsLoadingFetchMissionsAction,
        setIsLoadingFetchInstitutionHasWorkersAction
    }
)(withFeatureTogglesConsumer(App));
