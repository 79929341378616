import { IReduxAction } from "../store.types";
import PoleTypes from "../type/pole.types";

const initialState: any = {
    poles: [],
    pole: {}
};
export default function polesReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case PoleTypes.FETCH_POLES:
            return {
                ...state,
                poles: action.payload
            };
        case PoleTypes.FETCH_POLE:
            return {
                ...state,
                poles: action.payload
            };
        case PoleTypes.SET_IS_LOADING_FETCH_POLES:
            return {
                ...state,
                isLoadingFetchPoles: action.payload
            };
        case PoleTypes.SET_IS_LOADING_FETCH_POLE_BY_ID:
            return {
                ...state,
                isLoadingFetchPole: action.payload
            };
        default:
            return state;
    }
}
