import { IInstitutionGroup } from "../../../Models/InstitutionGroup";
import { PossibleFetchs, PossibleApis } from "../../module/constant/Api.constants";
import { fetchByIdFactory } from "../../module/factory/fetchById.factory";
import { fetchFactory } from "../../module/factory/fetch.factory";
import { IQueryParams } from "medgo-query-generator";

export async function fetchInstitutionGroups(idInstitution: number, params: IQueryParams): Promise<IInstitutionGroup> {
    return fetchByIdFactory<IInstitutionGroup>(PossibleFetchs.institutionGroups, PossibleApis.serviceApi)(
        idInstitution,
        {
            select: ["id", "name", "institution"]
        },
        params
    );
}

export async function fetchInstitutionsGroups(params: IQueryParams): Promise<IInstitutionGroup[]> {
    return fetchFactory<IInstitutionGroup>(PossibleFetchs.institutionGroups, PossibleApis.serviceApi)(
        {
            select: ["id", "name", "institution"]
        },
        params
    );
}
