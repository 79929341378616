import { combineReducers } from "redux";
import appReducer from "./app.reducer";
import authReducer from "./auth.reducer";
import adminReducer from "./admin.reducer";
import levelReducer from "./level.reducer";
import langReducer from "./lang.reducer";
import institutionsReducer from "./institution.reducer";
import institutionsOptionsReducer from "./institutionOption.reducer";
import institutionsGroupsReducer from "./institutionGroup.reducer";
import jobsReducer from "./job.reducer";
import filtersReducer from "./filter.reducer";
import polesReducer from "./pole.reducer";
import servicesReducer from "./service.reducer";
import workersReducer from "./worker.reducer";
import specialtiesReducer from "./specialty.reducer";
import networkReducer from "./network.reducer";
import missionReducer from "./mission.reducer";
import institutionHasWorkerReducer from "./institutionHasWorker.reducer";
import missionMotifCreationReducer from "./missionMotifCreation.reducer";

export default combineReducers({
    app: appReducer,
    auth: authReducer,
    admin: adminReducer,
    level: levelReducer,
    language: langReducer,
    institutions: institutionsReducer,
    institutionsOptions: institutionsOptionsReducer,
    institutionsGroups: institutionsGroupsReducer,
    jobs: jobsReducer,
    poles: polesReducer,
    services: servicesReducer,
    filters: filtersReducer,
    workers: workersReducer,
    specialties: specialtiesReducer,
    network: networkReducer,
    missions: missionReducer,
    institutionHasWorker: institutionHasWorkerReducer,
    missionMotifCreation: missionMotifCreationReducer
});
