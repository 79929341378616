enum InstitutionGroupTypes {
    FETCH_INSTITUTION_GROUPS = "FETCH_INSTITUTION_GROUPS",
    FETCH_INSTITUTIONS_GROUPS_CLUSTER = "FETCH_INSTITUTIONS_GROUPS_CLUSTER",
    FETCH_INSTITUTIONS_GROUPS_GROUPING = "FETCH_INSTITUTIONS_GROUPS_GROUPING",
    SET_IS_LOADING_FETCH_INSTITUTION_GROUPS = "SET_IS_LOADING_FETCH_INSTITUTION_GROUPS",
    SET_IS_LOADING_FETCH_INSTITUTIONS_GROUPS_CLUSTER = "SET_IS_LOADING_FETCH_INSTITUTIONS_GROUPS_CLUSTER",
    SET_IS_LOADING_FETCH_INSTITUTIONS_GROUPS_GROUPING = "SET_IS_LOADING_FETCH_INSTITUTIONS_GROUPS_GROUPING"
}

export default InstitutionGroupTypes;
