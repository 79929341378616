import { INetworkLang } from "../interface/Network.interface";

export const frNetwork: INetworkLang = {
    pendingRequests: "Demande(s) à traiter",
    inNetworkWorkers: "Remplaçants dans le réseau",
    workerLegend1: "Salarié(s)",
    workerLegend2: "Agent(s) de l'établissement",
    contractorLegend1: "Vacataire(s) externe(s)",
    contractorLegend2: "Contractuel(s)",
    interimLegend1: "Intérimaire(s)",
    interimLegend2: "Vacataire(s) Agence",
    joinNetworkRequest: "Demande(s) d'ajout dans le réseau",
    skillsValidationRequest: "Demande(s) de compétences",
    profilesThanMayInterestYou: "Profil(s) pouvant vous intéresser",
    networkStatus: "état du réseau actuel",
    networkEvolution: "évolution du réseau",
    workersOrAgents: "Salariés / Agents",
    contractors: "Vacataires / Contractuels",
    agencyWorkers: "Vacataire agence",
    allNetwork: "Tout le réseau",
    activeNetwork: "Actif",
    onGoing: "en cours",
    activeUserTooltip:
        "<strong>Un utilisateur est actif</strong> lorsque, sur les 3 derniers mois ou sur les mois à venir, au moins une mission lui a été attribuée, il a candidaté pour une mission ou il a renseigné une disponibilité/indisponibilité dans son agenda.",
    willBeAvailableInTheFuture: "En cours de création par notre équipe technique."
};
