import { isNullOrUndefined } from "util";
import moment from "moment";
import { IInstitutionHasWorker, IReducedInstitutionHasWorker } from "../../../Models/InstitutionHasWorker";

export function getInstitutionHasWorkersGroupedByWorkerAndInstitution(
    institutionHasWorkers: IInstitutionHasWorker[]
): { [key: number]: IReducedInstitutionHasWorker } {
    return institutionHasWorkers.reduce((reducedInstitutionHasWorkers, institutionHasWorker): {
        [key: number]: IReducedInstitutionHasWorker;
    } => {
        if (reducedInstitutionHasWorkers[institutionHasWorker.worker]) {
            reducedInstitutionHasWorkers[institutionHasWorker.worker].job.push(institutionHasWorker.job);
            reducedInstitutionHasWorkers[institutionHasWorker.worker] = {
                ...reducedInstitutionHasWorkers[institutionHasWorker.worker],
                institution: reducedInstitutionHasWorkers[institutionHasWorker.worker].institution.concat(
                    institutionHasWorker.institution
                ),
                statut: reducedInstitutionHasWorkers[institutionHasWorker.worker].statut.concat(
                    institutionHasWorker.statut
                ),
                createdAt: reducedInstitutionHasWorkers[institutionHasWorker.worker].createdAt.concat(
                    moment(institutionHasWorker.createdAt)
                ),
                deletedAt: reducedInstitutionHasWorkers[institutionHasWorker.worker].deletedAt.concat(
                    moment(institutionHasWorker.deletedAt)
                ),
                isDeletedFromNetwork: reducedInstitutionHasWorkers[institutionHasWorker.worker].isDeletedFromNetwork
                    .concat(institutionHasWorker.isDeletedFromNetwork)
                    .filter((e: boolean | null): boolean => !isNullOrUndefined(e))
            };
        } else {
            reducedInstitutionHasWorkers[institutionHasWorker.worker] = {
                ...institutionHasWorker,
                institution: [institutionHasWorker.institution],
                job: [institutionHasWorker.job],
                statut: [institutionHasWorker.statut],
                createdAt: [moment(institutionHasWorker.createdAt)],
                deletedAt: [moment(institutionHasWorker.deletedAt)],
                worker: institutionHasWorker.worker,
                isDeletedFromNetwork: [institutionHasWorker.isDeletedFromNetwork]
            };
        }
        return reducedInstitutionHasWorkers;
    }, {} as any);
}
