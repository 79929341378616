import { IReduxAction } from "../store.types";
import AppTypes from "../type/app.types";

const initialState: any = {
    isLoadingApp: false
};
export default function appReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case AppTypes.SET_IS_LOADING_APP:
            return {
                ...state,
                isLoadingApp: action.payload
            };
        default:
            return state;
    }
}
