import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./scss/index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store";
import { QueryClientProvider } from "react-query";

import { getQueryClient } from "./module/query/queryClient";
import { FeatureTogglesProvider } from "./context/feature-toggles/FeatureToggles.context";

const queryClient = getQueryClient();
class AppProvider extends Component {
    render(): JSX.Element {
        return (
            <QueryClientProvider client={queryClient}>
                <FeatureTogglesProvider>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </FeatureTogglesProvider>
            </QueryClientProvider>
        );
    }
}

ReactDOM.render(<AppProvider />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
