import { IHRMonitoringLang } from "../interface/HRMonitoring.interface";

export const frHRMonitoring: IHRMonitoringLang = {
    nbOfWorkingHoursFulfilled: "Nombre d’heures réalisées par type de remplaçant",
    workerOrAgent: "Salarié - Agent de l’établissement",
    temporaryWorkerOrContractor: "Vacataire interne - Contractuel",
    interimOrAgencyContractor: "Interim - Vacataire Agence",
    pool: "Pool",
    total: "Total",
    weekBeginningOnXXX: "Semaine du __VAR",
    missionMotifDistribution: "Répartition des motifs de remplacement",
    absenteeism: "Absentéisme",
    needInReiforcement: "Besoin de renfort",
    vacantPosition: "Poste vacant",
    vacation: "Congés",
    other: "Autre",
    institutionAttractiveness: "Attractivité des établissements",
    institutionAttractivenessTooltip:
        "L’<strong>attractivité d’un établissement</strong> correspond à la proportion de missions pourvues par rapport à l’ensemble de missions postées dans cet établissement.",
    poleAttractiveness: "Attractivité des pôles",
    poleAttractivenessTooltip:
        "L’<strong>attractivité d’un pôle</strong> correspond à la proportion de missions pourvues par rapport à l’ensemble de missions postées dans ce pôle.",
    serviceAttractiveness: "Attractivité des services",
    serviceAttractivenessTooltip:
        "L’<strong>attractivité d’un service</strong> correspond à la proportion de missions pourvues par rapport à l’ensemble de missions postées dans ce service.",
    hourFirstLetter: "h",
    notEnoughMissionsForThisInstitution: "Pas assez de missions pour cet établissement (5 missions minimum)",
    notEnoughMissionsForThisPole: "Pas assez de missions pour ce pôle (5 missions minimum)",
    notEnoughMissionsForThisService: "Pas assez de missions pour ce service (5 missions minimum)"
};
