import { IFilterLang } from "../interface/Filter.interface";

export const esFilter: IFilterLang = {
    institution: "Centro / Ubicación",
    job: "profesión",
    lastFourWeeks: "4 últimas semanas ",
    lastSixMonths: "Últimos 6 meses",
    lastTwelveMonths: "Últimos 12 meses",
    lastYear: "Año pasado ",
    onGoingYear: "Año en curso ",
    period: "periodo",
    pole: "área",
    service: "unidad"
};
