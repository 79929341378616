import { IQueryParams } from "medgo-query-generator";
import { PossibleApis, PossibleFetchs } from "../../module/constant/Api.constants";
import { postFetchFactory } from "../../module/factory/postFetch.factory";
import { ISignUpCode } from "../../../Models/SignUpCode";

const SignUpCodeDefaultSelect = ["id", "code", "institutions"];

export async function fetchSignUpCodes(query: IQueryParams): Promise<ISignUpCode[]> {
    const signupCodes = await postFetchFactory<ISignUpCode>(
        PossibleFetchs.signUpCode,
        PossibleApis.serviceApi,
        2
    )(
        {
            select: SignUpCodeDefaultSelect
        },
        query
    );
    return signupCodes;
}
