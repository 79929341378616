import { IInstitution } from "../../../Models/Institution";
import { Permission, UserPermissions } from "../../types/auth/permission.types";

const INSTITUTION_PATTERN = "Hublo::Match::Admin::Institution:__INSTITUTION_ID__";

const institutionScope = (institutionId: number): string => {
    return INSTITUTION_PATTERN.replace("__INSTITUTION_ID__", institutionId.toString());
};

const hasPermissionWithinInstitution = (permission: Permission, permissions: UserPermissions, id: number): boolean => {
    return Boolean(permissions[institutionScope(id)]?.includes(permission));
};

export const isAllowedTo = ({
    institutions,
    permission,
    permissions
}: {
    institutions?: number[];
    permission: Permission;
    permissions?: UserPermissions;
}): boolean => {
    if (!institutions || !permissions) return false;
    return institutions.some((id): boolean => hasPermissionWithinInstitution(permission, permissions, id));
};
