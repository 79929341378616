import { fetchByIdFactory } from "../../module/factory/fetchById.factory";
import { IInstitution } from "../../../Models/Institution";
import { fetchFactory } from "../../module/factory/fetch.factory";
import { IQueryParams } from "medgo-query-generator";
import { PossibleFetchs, PossibleApis, FetchLimits } from "../../module/constant/Api.constants";

export async function fetchInstitutionById(id: number): Promise<IInstitution> {
    return fetchByIdFactory<IInstitution>(PossibleFetchs.institutions, PossibleApis.serviceApi)(id, {
        select: ["id", "name"]
    });
}
export async function fetchInstitutions(params: IQueryParams): Promise<IInstitution[]> {
    return fetchFactory<IInstitution>(PossibleFetchs.institutions, PossibleApis.serviceApi)(
        {
            select: ["id", "name"],
            limit: FetchLimits.institutions
        },
        params
    );
}
