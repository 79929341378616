import React from "react";
import { Close, ErrorOutlineOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Collapse, IconButton, Typography } from "@material-ui/core";

export const useSlowdownBannerStyles = makeStyles(theme => ({
    collapseContainer: {
        flexShrink: 0
    },
    root: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        gap: "16px",
        padding: "16px 64px",
        background: "#FCE0AA",
        color: "#131415",
        minHeight: "56px",
        [theme.breakpoints.down("md")]: {
            padding: "16px"
        }
    },
    closeIcon: {
        padding: 0,
        color: "#131415"
    },
    text: {
        fontSize: 16,
        fontWeight: 700,
        fontFamily: "Nunito",
        color: "#131415",
        lineHeight: "24px"
    }
}));

export const SlowDownBanner = ({ onClickClose, text }: { onClickClose: () => void; text: string }) => {
    const classes = useSlowdownBannerStyles();
    return (
        <Collapse
            in={true}
            classes={{
                wrapper: classes.collapseContainer
            }}
            unmountOnExit
        >
            <div className={classes.root}>
                <ErrorOutlineOutlined />
                <Typography className={classes.text}>{text}</Typography>
                <IconButton className={classes.closeIcon} aria-label="close" onClick={onClickClose}>
                    <Close />
                </IconButton>
            </div>
        </Collapse>
    );
};
