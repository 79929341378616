import { fetchByIdFactory } from "../../module/factory/fetchById.factory";
import { IJob } from "../../../Models/Job";
import { fetchFactory } from "../../module/factory/fetch.factory";
import { IQueryParams } from "medgo-query-generator";
import { PossibleFetchs, PossibleApis, FetchLimits } from "../../module/constant/Api.constants";

export async function fetchJobById(id: number): Promise<IJob> {
    return fetchByIdFactory<IJob>(PossibleFetchs.jobs, PossibleApis.serviceApi)(id, {
        select: ["id", "name"]
    });
}
export async function fetchJobs(params: IQueryParams): Promise<IJob[]> {
    return fetchFactory<IJob>(PossibleFetchs.jobs, PossibleApis.serviceApi)(
        {
            select: ["id", "name"],
            limit: FetchLimits.jobs
        },
        params
    );
}
