import { INetworkLang } from "../interface/Network.interface";

export const enNetwork: INetworkLang = {
    pendingRequests: "Pending requests",
    inNetworkWorkers: "Locums in your network",
    workerLegend1: "Employee(s)",
    workerLegend2: "Agent(s) of the institution",
    contractorLegend1: "External temp(s)",
    contractorLegend2: "Contractor(s)",
    interimLegend1: "Interim",
    interimLegend2: "Temp(s) from Agencies",
    joinNetworkRequest: "Request(s) to be added to the network",
    skillsValidationRequest: "Request(s) for expertise",
    profilesThanMayInterestYou: "Profile(s) you may be interested in",
    networkStatus: "current network status",
    networkEvolution: "network evolution",
    workersOrAgents: "Employees / Agents",
    contractors: "Contractors",
    agencyWorkers: "Interim locum",
    allNetwork: "All network",
    activeNetwork: "Active",
    onGoing: "ongoing",
    activeUserTooltip:
        "<strong>A user is active</strong> when, over the last 3 months or over the coming months, at least one mission has been assigned to him, he has applied for a mission or he has entered an availability/unavailability in his calendar.",
    willBeAvailableInTheFuture: "Under development by our technical team."
};
