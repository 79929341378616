import { INetworkLang } from "../interface/Network.interface";

export const esNetwork: INetworkLang = {
    activeNetwork: "Activo ",
    activeUserTooltip:
        "<strong>Un usuario está activo</strong> cuando, en los 3 últimos o próximos meses, se le ha asignado al menos una misión, ha solicitado una misión o ha introducido una disponibilidad/indisponibilidad en su calendario.",
    agencyWorkers: "Interino procedente de una agencia",
    allNetwork: "Toda la red",
    contractorLegend1: "Interino(s) externo(s)",
    contractorLegend2: "Contractual(es) ",
    contractors: "Interinos / Contractuales",
    inNetworkWorkers: "Sustitutos en la red",
    interimLegend1: "Interino(s) ",
    interimLegend2: "Agencia de interino(s)",
    joinNetworkRequest: "Solicitud(es) de adhesión a la red",
    networkEvolution: "evolución de la red ",
    networkStatus: "Estado de la red actual ",
    onGoing: "en progreso",
    pendingRequests: "Solicitud(es) que tramitar ",
    profilesThanMayInterestYou: "Perfil(es) que le pueden interesar",
    skillsValidationRequest: "Solicitud(es) de acreditación de competencias",
    willBeAvailableInTheFuture: "Pendiente de creación por nuestro equipo técnico.",
    workerLegend1: "Empleado(s) ",
    workerLegend2: "Agente(s) del establecimiento",
    workersOrAgents: "Empleados / Suplentes "
};
