import InstitutionGroupTypes from "../type/institutionGroup.types";
import { IReduxAction } from "../store.types";
import {
    fetchInstitutionGroups,
    fetchInstitutionsGroups
} from "../../service/institutionGroup/institutionGroup.service";
import { IInstitutionGroup } from "../../../Models/InstitutionGroup";
import { IQueryParams } from "medgo-query-generator";

export function fetchInstitutionGroupsAction(
    idInstitution: number,
    params: IQueryParams = {}
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        dispatch({
            type: InstitutionGroupTypes.SET_IS_LOADING_FETCH_INSTITUTION_GROUPS,
            payload: true
        });
        const institutionGroups = await fetchInstitutionGroups(idInstitution, params);
        dispatch({
            type: InstitutionGroupTypes.FETCH_INSTITUTION_GROUPS,
            payload: { [idInstitution]: institutionGroups }
        });
        dispatch({
            type: InstitutionGroupTypes.SET_IS_LOADING_FETCH_INSTITUTION_GROUPS,
            payload: false
        });
    };
}

export function fetchInstitutionsGroupsGroupingAction(
    idInstitutions: number[],
    params: IQueryParams = {}
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        dispatch({
            type: InstitutionGroupTypes.SET_IS_LOADING_FETCH_INSTITUTIONS_GROUPS_GROUPING,
            payload: true
        });
        const institutionsGroups = await fetchInstitutionsGroups(params);
        dispatch({
            type: InstitutionGroupTypes.FETCH_INSTITUTIONS_GROUPS_GROUPING,
            payload: idInstitutions.reduce((acc, currentIdInstitution: number): {
                [key: number]: IInstitutionGroup[];
            } => {
                const filteredInstitutionsGroups = institutionsGroups.filter((institutionGroups): number | undefined =>
                    institutionGroups.institution.find((institution): boolean => institution === currentIdInstitution)
                );
                acc[currentIdInstitution] =
                    filteredInstitutionsGroups.length > 0 ? filteredInstitutionsGroups : undefined;
                return acc;
            }, {} as any)
        });
        dispatch({
            type: InstitutionGroupTypes.SET_IS_LOADING_FETCH_INSTITUTIONS_GROUPS_GROUPING,
            payload: false
        });
    };
}

export function fetchInstitutionsGroupsClusterAction(
    idInstitutions: number[],
    params: IQueryParams = {}
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        dispatch({
            type: InstitutionGroupTypes.SET_IS_LOADING_FETCH_INSTITUTIONS_GROUPS_CLUSTER,
            payload: true
        });
        const institutionsGroups = await fetchInstitutionsGroups(params);
        dispatch({
            type: InstitutionGroupTypes.FETCH_INSTITUTIONS_GROUPS_CLUSTER,
            payload: idInstitutions.reduce((acc, currentIdInstitution: number): {
                [key: number]: IInstitutionGroup[];
            } => {
                const filteredInstitutionsGroups = institutionsGroups.filter((institutionGroups): number | undefined =>
                    institutionGroups.institution.find((institution): boolean => institution === currentIdInstitution)
                );
                acc[currentIdInstitution] =
                    filteredInstitutionsGroups.length > 0 ? filteredInstitutionsGroups : undefined;
                return acc;
            }, {} as any)
        });
        dispatch({
            type: InstitutionGroupTypes.SET_IS_LOADING_FETCH_INSTITUTIONS_GROUPS_CLUSTER,
            payload: false
        });
    };
}
