import LevelTypes from "../type/level.types";
import { IReduxAction } from "../store.types";
import { fetchLevel } from "../../service/level/level.service";

export function fetchLevelAction(idLevel: number): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const level = await fetchLevel(idLevel);
        dispatch({
            type: LevelTypes.FETCH_LEVEL,
            payload: level
        });
    };
}
