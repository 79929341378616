import { IReduxAction } from "../store.types";
import PoleTypes from "../type/pole.types";
import { fetchPoleById, fetchPoles } from "../../service/pole/pole.service";
import FilterTypes from "../type/filter.types";

export function setIsLoadingFetchPolesAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PoleTypes.SET_IS_LOADING_FETCH_POLES,
            payload: bool
        });
    };
}

export function fetchPolesAction(idPoles: number[]): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const poles = await fetchPoles({
            query: {
                id: idPoles
            }
        });
        dispatch({
            type: PoleTypes.FETCH_POLES,
            payload: poles
        });
        dispatch({
            type: FilterTypes.SET_FILTERED_POLES,
            payload: poles
        });
    };
}

export function setIsLoadingFetchPoleByIdAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: PoleTypes.SET_IS_LOADING_FETCH_POLE_BY_ID,
            payload: bool
        });
    };
}

export function fetchPoleByIdAction(id: number): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const pole = await fetchPoleById(id);
        dispatch({
            type: PoleTypes.FETCH_POLE,
            payload: pole
        });
    };
}
