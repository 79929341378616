import { IReduxAction } from "../store.types";
import { fetchMissionMotifCreations } from "../../service/missionMotifCreation/missionMotifCreation.service";
import MissionMotifCreationTypes from "../type/missionMotifCreation.types";

export function fetchMissionMotifCreationsAction(
    idInstitutions: number[]
): (dispatch: (x: IReduxAction) => Promise<void>) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        dispatch({
            type: MissionMotifCreationTypes.SET_IS_LOADING_FETCH_MISSION_MOTIF_CREATION,
            payload: true
        });
        const missionMotifCreations = await fetchMissionMotifCreations({
            query: {
                idInstitution: idInstitutions
            }
        });
        dispatch({
            type: MissionMotifCreationTypes.FETCH_MISSION_MOTIF_CREATIONS,
            payload: missionMotifCreations
        });
        dispatch({
            type: MissionMotifCreationTypes.SET_IS_LOADING_FETCH_MISSION_MOTIF_CREATION,
            payload: false
        });
    };
}
